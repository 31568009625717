import React from 'react';
import { Typography, Row, Col, Spin } from 'antd';
import { useRealmContext } from '../contexts/RealmProvider';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import RealmObjectTree from './RealmObjectTree';
import { LoadingOutlined } from '@ant-design/icons'; // Add this import
import './css/RealmObjectsDisplay.css'; // Ensure the path is correct

const { Title } = Typography;

const RealmObjectsDisplay = () => {
  const { realmID } = useRealmContext();  // Get realmID from the context
  const { isLoading, realmObject, realmObjectID } = useRealmObjectContext(); // Get loading state and realm objects

  // Handle case when realmID is null
  if (!realmID) {
    return (
      <div className="realm-objects-display fab-fill-parent">
        <Row justify="center">
          <Col span={24}>
            <Title level={2} className="custom-title center-element">
              No realm selected
            </Title>
          </Col>
        </Row>
      </div>
    );
  }

  // If realmID is available, render the content
  return (
    <div className="realm-objects-display fill-width">
      <Row  className="fill-parent">
        <Col span={24}>
          {isLoading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <RealmObjectTree realmObject={realmObject} realmObjectID={realmObjectID} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RealmObjectsDisplay;
