import { useState, useCallback, useMemo } from 'react';
import ApiCaller from '../services/ApiCaller';
import { message } from 'antd';
import PortalConfiguration, { CampaignServiceStatics } from '../configuration/config';
import { useLoadingState } from './useLoadingState';
import  CampaignObject from '../models/CampaignObject'; // Ensure correct import path
import { useAuthContext } from '../contexts/AuthProvider';

const useCampaignService = () => {

  const { setLoading } = useLoadingState(); // Custom loading hook
  const [campaignError, setCampaignError] = useState(null); // Error state
  const { sessionID, username } = useAuthContext(); // Auth context
  const apiCaller = useMemo(() => new ApiCaller(PortalConfiguration.campaignServiceURL), []); // API caller

  // Create or update a campaign record
  const saveCampaignRecord = useCallback(async (recordData, isNew = true) => {
    setLoading('isLoadingCampaign', true);
    setCampaignError(null); // Clear any previous errors

    try {
      const service = isNew ? '/new' : '/update';

      // Create a new DBRecord instance
      const record = new CampaignObject(
        recordData.PK_CampaignID,
        recordData.SK_RecordID,
        recordData.GSI_PK_REALMID,
        recordData.payload,
        recordData.type,
        recordData.created,
        recordData.updated
      );

      const result = await apiCaller.callApi({
        service,
        method: 'PUT',
        headers: {}, // Add additional headers if needed
        body: record,
        authorization: sessionID,
        userID: username,
      });
      message.success(`Campaign record ${isNew ? 'created' : 'updated'} successfully`);
      return result;
    } catch (error) {
      setCampaignError(error);
      message.error(`Error ${isNew ? 'creating' : 'updating'} campaign record`);
      console.error('Error saving campaign record:', error);
      throw error;
    } finally {
      setLoading('isLoadingCampaign', false);
    }
  }, [apiCaller, sessionID, setLoading, username]);

  // Delete a specific campaign record
  const deleteCampaignRecord = useCallback(async (realmID, campaignId, recordId) => {
    setLoading('isLoadingCampaign', true);
    setCampaignError(null); // Clear previous errors

    try {
      const result = await apiCaller.callApi({
        service: `/delete`,
        method: 'DELETE',
        params: {
          [`${CampaignServiceStatics.dynamoPrimaryTablePartitionKey}`]: campaignId,
          [`${CampaignServiceStatics.dynamoPrimaryTableSortKey}`]: recordId,
        },
        authorization: sessionID,
        userID: username,
      });
      
      return result;
    } catch (error) {
      setCampaignError(error);
      message.error('Error deleting campaign record');
      console.error('Error deleting campaign record:', error);
      throw error;
    } finally {
      setLoading('isLoadingCampaign', false);
    }
  }, [apiCaller, sessionID, setLoading, username]);

  // Fetch all records for a specific campaign by CampaignID
  const fetchCampaignRecords = useCallback(async (campaignId, type = null) => {
    setLoading('isLoadingCampaign', true);
    setCampaignError(null); // Clear previous errors

    try {
      const params = {
        [`${CampaignServiceStatics.dynamoPrimaryTablePartitionKey}`]: campaignId,
      };
      if (type) {
        params.type = type;
      }

      const result = await apiCaller.callApi({
        service: '/campaign',
        method: 'GET',
        authorization: sessionID,
        userID: username,
        params,
      });

      return result.records;
    } catch (error) {
      setCampaignError(error);
      console.error('Error fetching campaign records:', error);
      throw error;
    } finally {
      setLoading('isLoadingCampaign', false);
    }
  }, [apiCaller, sessionID, setLoading, username]);

  // Delete all records associated with a campaign by CampaignID
  const deleteCampaign = useCallback(async (realmID,item) => {
    setLoading('isLoadingCampaign', true);
    setCampaignError(null); // Clear previous errors
    const campaignId = item.PK_CampaignID;
    try {
      var recordCount = 0;
      // First, fetch all records for the campaign
      const campaignRecords = await fetchCampaignRecords(campaignId);
      // Delete each record associated with the campaign
      console.log(campaignRecords);
      for (const record of campaignRecords) {
        recordCount++;
        await deleteCampaignRecord(realmID,record.PK_CampaignID, record.SK_RecordID);
      }

      message.success(`${item.payload.name} and ${recordCount} associated records deleted successfully`);
    } catch (error) {
      setCampaignError(error);
      message.error('Error deleting campaign and its records');
      console.error('Error deleting campaign and its records:', error);
      throw error;
    } finally {
      setLoading('isLoadingCampaign', false);
    }
  }, [fetchCampaignRecords, deleteCampaignRecord, setLoading]);

  // Fetch campaigns related to a specific realm
  const fetchRealmCampaigns = useCallback(async (realmId) => {
    setLoading('isLoadingCampaign', true);
    setCampaignError(null); // Clear previous errors

    try {
      const result = await apiCaller.callApi({
        service: '/realm',
        method: 'GET',
        params: {  [`${CampaignServiceStatics.dynamoPrimaryTableGlobalSecondaryKey}`]: realmId,
                    type: 'Primary' },
      });

      return result.records;
    } catch (error) {
      setCampaignError(error);
      console.error('Error fetching realm campaigns:', error);
      throw error;
    } finally {
      setLoading('isLoadingCampaign', false);
    }
  }, [apiCaller, setLoading]);

  return {
    campaignError,           // Any error that occurred during an operation
    saveCampaignRecord,      // Function to create or update a specific campaign record
    deleteCampaignRecord,    // Function to delete a specific campaign record
    fetchCampaignRecords,    // Function to fetch all records by CampaignID
    deleteCampaign,          // Function to delete all records of a campaign by CampaignID
    fetchRealmCampaigns,     // Function to fetch campaigns by realm ID
  };
};

export default useCampaignService;
