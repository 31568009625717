import React from 'react';
import { Dropdown, Menu } from 'antd';
import './css/RealmObjectTreeTitle.css'; // Add this CSS for the layout
import IconifiedText from './IconifiedText';
import {
  DeleteOutlined, // For deleting
  GlobalOutlined, // For making public
  CopyOutlined, // For copying link
  RightOutlined, // For making child of upper item
  LeftOutlined, // For moving to higher level
} from '@ant-design/icons';

const RealmObjectTreeTitle = ({ name, payload, onPromoteToHigherLevel, onMakeChildOfUpper, onDelete, onMakePublic, isPublic, hasParent, hasSiblingBefore, isRealmObject }) => {
  const menuItems = [
    ...(hasParent ?
      [
        {
          key: 'promoteToHigherLevel',
          label: 'Move to higher level',
          onClick: onPromoteToHigherLevel,
          icon: <LeftOutlined />,
        }
      ] : []),
    ...(hasSiblingBefore ?
      [
        {
          key: 'makeChildOfUpper',
          label: 'Make child of upper item',
          onClick: onMakeChildOfUpper,
          icon: <RightOutlined />,
        }
      ] : [])
  ];

  // Stop event propagation when clicking inside the Dropdown or hovering
  const handleMenuClick = (e) => {
    e.stopPropagation();
  };


  return (
    <div
      className={`realm-object-title`} >
      {/* IconifiedText on the left */}
      <IconifiedText
        text={name || 'Unnamed'}
        iconFileIndex={payload?.iconFileIndex || 0}
        iconIndex={payload?.iconIndex || 0}
        iconDisplaySize={30}
        textType="large"
        textStyle="tree"
      />

      {/* Kebab menu aligned to the right */}
      <Dropdown menu={{ items: menuItems }} trigger={['click']} className='kebab-menu'>
        <span className="vertical-kebab-icon" onClick={handleMenuClick}>⋮</span>
      </Dropdown>
    </div>
  );
};

export default RealmObjectTreeTitle;
