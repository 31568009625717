import React from 'react';
import { Space, Typography } from 'antd';
import IconDisplay from './IconDisplay'; // Adjust the import path as necessary
import "./css/IconifiedText.css"; // Ensure you create and import this CSS file
import "../App.css";

const { Text } = Typography;

const StyledTabContent = ({ 
  text, 
  iconFileIndex, 
  iconIndex, 
  iconDisplaySize, 
  iconPosition = "left",
  textType = "medium",
  textStyle = "secondary",
  backgroundClassName = "",
  higlight = false,
}) => {

  const iconElement = (iconFileIndex !== undefined && iconIndex !== undefined) && (
    <IconDisplay
      imageTableIndex={iconFileIndex}
      iconIndex={iconIndex}
      displayWidth={iconDisplaySize ? iconDisplaySize : 25}
      displayHeight={iconDisplaySize ? iconDisplaySize : 25}
      className='fab-card-header-icon'
    />
  );

  return (
    <Space className={`iconified-text ${backgroundClassName} `}>
      {iconPosition === "left" && iconElement}
      <Text className={`custom-text text-${textType} text-${textStyle}`}>
        {text}
      </Text>
      {iconPosition === "right" && iconElement}
    </Space>
  );
};

export default StyledTabContent;
