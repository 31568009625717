import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Tabs, Grid, Layout, Button, Flex, message } from 'antd';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

import IconifiedTitle from './IconifiedTitle';
import StyledTabContent from './StyledTabContent';
import FoundryTab from './FoundryTab';
import RealmObjectActionBar from './RealmObjectActionBar';
import PresentationTab from './PresentationTab';
import EmptyControlContent from './EmptyControlContent';
import ShareTab from './ShareTab';
import { useAppContext } from '../contexts/AppProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import { DoubleLeftOutlined, RightOutlined } from '@ant-design/icons';
import '../App.css';
import './css/RealmObject.css';
import { useRealmContext } from '../contexts/RealmProvider';
import LinksPanel from './LinksPanel';
import ContextTab from './ContextTab';
import CustomSpinner from './CustomSpinner';
import { useAttributeManagement } from '../hooks/useAttributeManagement';
import { useRealmObjectCrud } from '../hooks/useRealmObjectCrud';
import { useRealmObjectFetching } from '../hooks/useRealmObjectFetching';
import ApiCaller from '../services/ApiCaller';
import { useLinkManagement } from '../hooks/useLinkManagement';
import APIContext from '../types/APIContext';
import PortalConfiguration from '../configuration/config';
import useFabrication from '../hooks/useFabricate';
import useAccessControl from '../hooks/useAccessControl';

const { Sider, Content } = Layout;

const RealmObject = () => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const { featureFlags } = useAppContext();
  const [isLinksVisible, setIsLinksVisible] = useState(false);
  const { realmID } = useRealmContext(); // Access the realmID from the RealmContext
  const { sessionID, username, userPermissionLevel} = useAuthContext();
  const { deleteAccess } = useAccessControl(); // Import the deleteAccess function
  const {
    realmobjectDragTarget, 
    setRealmObjectDragTarget,
    realmObjectID,
    realmObject,
    realmObjectList,
    updateRealmObject,
    setRealmObjectList,
    setRealmObjectID,
    setRealmObjectEdited,
    updateLinkedObjects,
    realmObjectAPICaller,
    isLoadingObject,
    allowedAccessors,
    allowedCampaigns
  } = useRealmObjectContext();

  const { addContextAttribute, removeContextAttribute } = useAttributeManagement();
  const { saveRealmObject, deleteRealmObject } = useRealmObjectCrud(realmObjectAPICaller,sessionID, username, realmID);
  const { fetchRealmObjectList } = useRealmObjectFetching(realmObjectAPICaller, sessionID, username, ApiCaller.handleApiError);
  const { createLink } = useLinkManagement();
  const {randomText} = useFabrication(sessionID, realmID, username);

  const linkApiContext = new APIContext(new ApiCaller(PortalConfiguration.linkURL), sessionID, realmID, ApiCaller.handleApiError);

  const toggleSidePanel = () => {
    setIsLinksVisible(!isLinksVisible);
  };

  const handleQuestionChange = (newValue, label) => {
    const updatedQuestions = realmObject.payload.Questions.map((question) =>
      question.Label === label ? { ...question, CurrentValue: newValue } : question
    );
    updateRealmObject('Questions', updatedQuestions);
  };

  const handleAddAttribute = (attribute) => {
    if (realmObject) {
      addContextAttribute(attribute.name, attribute.value, attribute.notes, realmObject);
    }
  };

  const handleDeleteAttribute = (name) => {
    removeContextAttribute(name, realmObject);
  };

  const handleSave = async () => {
    if (!realmID || !realmObjectID) {
      console.error('Missing RealmID or RealmObjectID');
      return;
    }
    await saveRealmObject(realmObject);
    const updatedRealmObjectList = realmObjectList.map((item) => (item.RealmObjectID === realmObjectID ? realmObject : item));
    setRealmObjectList(updatedRealmObjectList)
    setRealmObjectEdited(false);
    fetchRealmObjectList(realmID,true);
  };

  const handleDelete = async () => {
    if (!realmID || !realmObjectID) {
      console.error('Missing RealmID or RealmObjectID');
      return;
    }
    console.log('Deleting object:', allowedAccessors,allowedCampaigns);
    // Delete all users from the campaign concurrently
    const campaignUsers = allowedAccessors;
    await Promise.all(campaignUsers.map(user => deleteAccess(user.accessId, user.targetId, true)));
    await Promise.all(allowedCampaigns.map(accessor => deleteAccess(accessor.accessId,
       accessor.targetId, true)));
    
    await deleteRealmObject(realmObjectID);
    setRealmObjectID(null);
    fetchRealmObjectList(realmID,false);
  };

  const handleTitleChange = (newValue) => {
    updateRealmObject('name', newValue);
  };

  const handleDrop = (info) => {
    info.preventDefault();
    const dragObject = realmobjectDragTarget;
    console.log('Dropped on RealmObject:', dragObject.key);
    const SK_REALMID_RealmObjectID = dragObject.key;
    const PK_REALMID_RealmObjectID = realmObjectID;
    if (SK_REALMID_RealmObjectID !== PK_REALMID_RealmObjectID) {
      createLink(PK_REALMID_RealmObjectID, SK_REALMID_RealmObjectID,linkApiContext);
      updateLinkedObjects();
      setRealmObjectDragTarget(null);
    } else {
      message.info("You can Love Yourself but you can't Link Yourself!");
    }
  };

  const handleDragOver = (info) => {
    info.preventDefault();
  };

  const processRandomText = async (textDescription) => {
    return await randomText(textDescription);
  }

  const getRandomDescription = async () => {
    const templateID = await realmObject.payload?.template?.templateID;
    const templateDescription = await realmObject.payload?.templateDescription;
    const description = `A name for table top rpg world's ${templateID} \n\n Described as ${templateDescription}`;
    return description;
  }

  const iconifiedTitleProps = realmObject && {
    title: realmObject.name || 'unnamed',
    iconFileIndex: realmObject.payload.iconFileIndex,
    iconIndex: realmObject.payload.iconIndex,
    iconDisplaySize: 45,
    titleSize: 'large',
    titleEditable: true,
    iconEditable: true,
    randomTextFunction: processRandomText,
    randomDescriptionFunction: getRandomDescription,
    onChange: handleTitleChange,
    onIconChange: (iconFileIndex, iconIndex) => {
      console.log('onIconChange', iconFileIndex, iconIndex);
      updateRealmObject('iconFileIndex', iconFileIndex);
      updateRealmObject('iconIndex', iconIndex);
    }
  };
  
  const tabProps = [
    {
      text: "Context",
      textType: "large",
      textStyle: "orange",
      backgroundClassName: "custom-tab-background",
    },
    {
      text: "Foundry",
      iconFileIndex: 31,
      iconIndex: 0,
      iconDisplaySize: 30,
      iconPosition: "right",
      textType: "large",
      textStyle: "orange",
      backgroundClassName: "custom-tab-background",
    },
    {
      text: "Presentation",
      textType: "large",
      textStyle: "orange",
      backgroundClassName: "custom-tab-background",
    },
    {
      text: "Share",
      textType: "large",
      textStyle: "orange",
      backgroundClassName: "custom-tab-background",
      highlight: realmObject?.publishedLevelACL === 'PUBLIC',
    },
  ];

  const isDisabled = !(realmID && realmObjectID);

  useEffect(() => {
    if (realmObject?.payload?.Questions) {
      setQuestions(realmObject.payload.Questions);
    } else {
      setQuestions([]);
    }
    if (Array.isArray(realmObject?.payload?.ContextAttribute)) {
      setAttributes(realmObject.payload.ContextAttribute);
    } else {
      setAttributes([]);
    }

  }, [realmObject]);

  // Create the tabs array conditionally based on the feature flag
  const items = [
    {
      key: '1',
      label: <StyledTabContent {...tabProps[2]} />,
      children: <PresentationTab />,
    },
    {
      key: '2',
      label: <StyledTabContent {...tabProps[0]} />,
      children: (
        <ContextTab
          questions={questions}
          attributes={attributes}
          loading={isLoadingObject}
          handleQuestionChange={handleQuestionChange}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          handleAddAttribute={handleAddAttribute}
          handleDeleteAttribute={handleDeleteAttribute}
        />
      ),
    },
    featureFlags.getUserFlag(userPermissionLevel,'REALMOBJECT_SHARE_TAB') && {
      key: '3',
      label: <StyledTabContent {...tabProps[3]}  />,
      children: <ShareTab />,
    },
    {
      key: '4',
      label: <StyledTabContent {...tabProps[1]} />,
      children: <FoundryTab />,
    },
  ].filter(Boolean); // Filter out undefined items (when the share tab is not included)

  // Check if realmObjectID is not loaded, show EmptyControlContent
  if (!realmObjectID) {
    return (
      <Row align="middle" justify="center" style={{ height: '100vh' }}>
        <Col>
          <EmptyControlContent emptyText="No Realm Object Loaded. Please select or create a Realm Object to continue." />
        </Col>
      </Row>
    );
  }
  
  // Regular content when realmObjectID is loaded
  return (
    <Layout
      id="Realm_Object_Editor"
      droppable='true'
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      
    >
    <Row className="panel-background-medium" gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} xl={24} className="realm-object-header">
          <Row justify="space-between" align="middle">
            <Col>
              {/* Flexbox for aligning IconifiedTitle and Spinner */}
              <Flex className="title-spinner-container">
                <IconifiedTitle {...iconifiedTitleProps} />
                {isLoadingObject && <CustomSpinner />}
              </Flex>
            </Col>
            <Col>
              <RealmObjectActionBar
                isDisabled={isDisabled}
                handleDelete={handleDelete}
                handleSave={handleSave}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Layout className={`${realmObject?.publishedLevelACL === "PUBLIC" ? "public-highlight" : ""}`}>
        <Content className={`panel-background-medium `}>
          {!isLinksVisible && (
            <Button
              id="toggle_links_panel"
              icon={<DoubleLeftOutlined />}
              onClick={toggleSidePanel}
              className="floating-links-button"
            >
              Links
            </Button>
          )}
          <Tabs
            defaultActiveKey="0"
            type="card"
            className={`custom-tabs title-dark tabs-background`}
            items={items}
          />
        </Content>

        <Sider
          width={isLinksVisible ? 300 : 0}
          className="realm-object-links-sider"
          collapsedWidth={0}
          collapsible
          collapsed={!isLinksVisible}
          trigger={null}
        >
          <div className="close-button-container">
            <Button type="text" icon={<RightOutlined />} onClick={toggleSidePanel} />
          </div>
          <div className="realm-object-links-panel-content">
            <LinksPanel />
          </div>
        </Sider>
      </Layout>
    </Layout>
  );
};

export default RealmObject;
