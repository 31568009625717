import React, { useState, useEffect } from "react";
import { Row, Col, Button, Grid } from "antd";
import "./css/StoryEditor.css";
import KeyValuesList from "./KeyValuesList";
import LogoutButton from "./logoutButton";
import RealmInput from "./RealmInput";
import PortalConfiguration from "../configuration/config";
import { useAuthContext } from "../contexts/AuthProvider";
import ChangePasswordForm from "./ChangePasswordForm"; // Import the new component

const UserStatusPanel = ({ drawerVisible }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false); // State to show/hide password form
  const { changePassword, loggedIn } = useAuthContext(); // Get context values
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const onFinish = (values) => {
    setLoading(true);
    changePassword(values.newPassword, values.newPasswordCheck)
      .then(() => {
        setLoading(false);
        setIsPasswordFormVisible(false); // Hide form after successful password change
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsPasswordFormVisible(false); // Hide form on cancel
    setError(null); // Clear any errors
  };

  useEffect(() => {
    if (!drawerVisible) {
      setError(null); // Clear any errors
      setIsPasswordFormVisible(false); // Reset form visibility when the drawer is closed
    }
  }, [drawerVisible]);

  return (
    <>
      {loggedIn ? (
        <Row gutter={[16, 16]} style={{ padding: "16px" }}>
           {(!screens.lg) ? (
          <Col span={24} >
            <RealmInput transform='no'/>
          </Col>): (null)}
          <Col span={24}>
            <LogoutButton />
          </Col>

          {/* Button to show the password change form */}
          <Col span={24}>
            {!isPasswordFormVisible && (
              <Button
                type="primary"
                onClick={() => setIsPasswordFormVisible(true)}
                className="primary"
              >
                Change Password
              </Button>
            )}
          </Col>

          {/* Conditionally render the ChangePasswordForm and pass handleCancel */}
          {isPasswordFormVisible && (
            <Col span={24}>
              <ChangePasswordForm
                onFinish={onFinish}
                loading={loading}
                drawerVisible={drawerVisible}
                handleCancel={handleCancel} // Pass cancel handler to the form
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Col>
          )}
        </Row>
      ) : (
        <></>
      )}
       {PortalConfiguration.env === "qa" && (
            <Col span={24}>
              <KeyValuesList />
            </Col>
          )}
    </>
  );
};

export default UserStatusPanel;
