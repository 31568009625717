class RealmObjectService {
    // Save a RealmObject via the API
    static async saveRealmObject(apiCaller, sessionID, username, realmID, realmObject, realmObjectID) {
      try {
        const response = await apiCaller.callApi({
          userID: username,
          service: '', // Replace with your endpoint for saving
          method: 'POST',
          authorization: sessionID,
          params: { RealmID: realmID, RealmObjectID: realmObjectID },
          body: realmObject,
        });
        return response;
      } catch (error) {
        console.error('Error saving RealmObject:', error);
        throw new Error(error.response?.data?.error || 'Failed to save RealmObject');
      }
    }
  
    // Ensure all realm objects have unique realmOrder, saving updates to the database
        static async ensureUniqueRealmOrder(items, apiCaller, sessionID, username, realmID) {
          // If items is null or undefined, treat it as an empty array
          if (!Array.isArray(items) || items.length === 0) {
            return []; // Return an empty array if no items are provided
          }
      
          let maxOrder = RealmObjectService.getMaxRealmOrder(items);
          const seenOrders = new Set();
      
          // Iterate through the items and ensure unique realmOrder
          const updatedItems = await Promise.all(
            items.map(async (item) => {
              let order = item.payload.realmOrder;
      
              if (order == null || seenOrders.has(order)) {
                maxOrder += 1;
                order = maxOrder;
      
                // Update the item's realmOrder in the payload
                const updatedPayload = {
                  ...item.payload,
                  realmOrder: order,
                };
      
                // Save the updated item to the database
                await RealmObjectService.saveRealmObject(
                  apiCaller,
                  sessionID,
                  username,
                  realmID,
                  { ...item, payload: updatedPayload }, // Updated realmObject
                  item.RealmObjectID
                );
              }
      
              seenOrders.add(order);
      
              return {
                ...item,
                payload: {
                  ...item.payload,
                  realmOrder: order,
                },
              };
            })
          );
      
          return updatedItems;
        }
    // Get the maximum realm order from a list of items
    static getMaxRealmOrder(items) {
      return items.reduce((max, item) => {
        const order = item.payload.realmOrder ?? -1;
        return order > max ? order : max;
      }, -1);
    }
  
    // Set realm order in a payload
    static setRealmOrder(payload, realmOrder) {
      payload.realmOrder = realmOrder;
      return payload;
    }
  
    // Add a context attribute to the payload
    static addContextAttribute(payload, attributeName, attributeValue, attributeNotes) {
      if (!Array.isArray(payload.ContextAttribute)) {
        payload.ContextAttribute = []; // Initialize as empty array if not already an array
      }
  
      payload.ContextAttribute.push({
        Name: attributeName,
        Value: attributeValue,
        Notes: attributeNotes,
      });
      return payload;
    }
  
    // Retrieve context attributes from the payload
    static getContextAttributes(payload) {
      console.log('Getting context attributes:', payload);
      return payload.ContextAttribute || [];
    }
  
    // Remove a context attribute by name from the payload
    static removeContextAttribute(payload, attributeName) {
      if (Array.isArray(payload.ContextAttribute)) {
        payload.ContextAttribute = payload.ContextAttribute.filter(
          (attribute) => attribute.Name !== attributeName
        );
      } else {
      }
      return payload;
    }
  }
  
  export default RealmObjectService;
  