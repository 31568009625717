class APIContext {
  constructor(apiCaller, sessionID, realmID,handleApiError) {
    this.apiCaller = apiCaller;
    this.sessionID = sessionID;
    this.realmID = realmID;
    this.handleApiError = handleApiError;
  }

  async callAPI(method, path, body) {
    return this.apiCaller(this.sessionID, this.realmID, method, path, body);
  }
}
export default APIContext;