import React from 'react';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { Typography, Spin, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useLinkManagement } from '../hooks/useLinkManagement';
import APIContext from '../types/APIContext';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config';
import { useAuthContext } from '../contexts/AuthProvider';
import { useRealmContext } from '../contexts/RealmProvider';

const { Text } = Typography;

const LinkDisplay = ({ item, type }) => {
  const { realmObjectList, setRealmObjectID, updateLinkedObjects, isFetchingLinks, setLoading  } = useRealmObjectContext();
  const { sessionID } = useAuthContext();
  const { realmID } = useRealmContext();
  const { deleteLink } = useLinkManagement();


  const ObjectID = type === 'destination' ? item.DestinationObjectID : item.SourceObjectID;

  // Function to find the name of a RealmObject by its ID
  const getObjectNameById = (id) => {
    const realmObject = realmObjectList.find(obj => obj.RealmObjectID === id);
    // Ensure that this function always returns a string (fallback to 'Unknown Object')
    return realmObject ? realmObject.name : 'Unknown Object';
  };

  const handleClick = () => {
    setRealmObjectID(ObjectID); // Update the realmObjectID in context
  };

  const handleDelete = () => {
    const apiContext = new APIContext(new ApiCaller(PortalConfiguration.linkURL), sessionID, realmID, ApiCaller.handleApiError);
    deleteLink(item.SourceObjectID, item.DestinationObjectID, apiContext);
    updateLinkedObjects();
  };

  return (
    isFetchingLinks ? (
      <div></div>
    ) : (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Text
          className="links-active"
          onClick={handleClick}
        >
          {getObjectNameById(ObjectID)} {/* Ensure that this always returns a string */}
        </Text>
        <Button
          type="text"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
          style={{ marginLeft: 'auto' }}
        />
      </div>
    )
  );
};

export default LinkDisplay;
