import React from 'react';
import { Tree } from 'antd';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import useRealmTreeHandler from '../hooks/useRealmTreeHandler'; // Custom hook
import './css/RealmObjectsDisplay.css';
import './css/TreeControl.css';
import '../App.css';

const RealmObjectTree = ({ realmObject }) => {
  const { setRealmObjectID} = useRealmObjectContext();
  const { treeData, expandedKeys, setExpandedKeys, onDrop, onDragStart, onDragEnd } = useRealmTreeHandler();

  const onSelect = (selectedKeys) => {
    if (selectedKeys?.length > 0) {
      setRealmObjectID(selectedKeys[0]);
    }
  };

  return (
    <Tree
      className="realm-object-tree"
      treeData={treeData}
      selectedKeys={[realmObject?.RealmObjectID]}
      expandedKeys={expandedKeys}
      onExpand={setExpandedKeys}
      onSelect={onSelect}
      draggable
      onDrop={onDrop}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    />
  );
};

export default RealmObjectTree;
