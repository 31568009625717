import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import PackagesService from '../services/PackagesService'; // Adjust the path as necessary
import ApiCaller from '../services/ApiCaller'; // Adjust the path as necessary
import PortalConfiguration from '../configuration/config'; // Adjust the path as necessary
import { useAuthContext } from './AuthProvider'; // Adjust the path as necessary
import { sortItemsByProperty } from '../utility/sorting'; // Import the helper function

// Create the context
const PackageContext = createContext();

export const usePackageContext = () => {
 
  const context = useContext(PackageContext);
  if (!context) {
    throw new Error('usePackageContext must be used within a PackageProvider');
  }
  return context;
};

// Create the provider component
export const PackageProvider = ({ children }) => {
  const { username, sessionID } = useAuthContext(); // Accessing username and sessionID from AuthContext
  const [userPackages, setUserPackages] = useState([]);
  const [realmsPackage, setRealmsPackage] = useState(null);
  const [templateCollectionsPackage, setTemplateCollectionsPackage] = useState(null);
  const apiCallerRef = useRef(new ApiCaller(PortalConfiguration.packagesURL)); // Initialize ApiCaller with useRef
  const apiCaller = apiCallerRef.current;

  const clearPackages = () => {
    setUserPackages([]);
    setRealmsPackage(null);
    setTemplateCollectionsPackage(null);
  };
  // Define a function to load the packages
  const loadPackages = useCallback(async () => {
    if (!sessionID || !username) {
      console.error('SessionID or Username is missing');
      return;
    }
    try {
      const queryParams = { userId: username }; // Use username as userID in query parameters
      const packages = await PackagesService.getPackages(apiCaller, sessionID, queryParams);

      // Store all packages
      setUserPackages(packages);

      // Find specific packages if needed and access their payload items
      const realmsPkg = packages.find(pkg => pkg.packageId === 'Realms') || null;
      const templateCollectionsPkg = packages.find(pkg => pkg.packageId === 'TemplateCollections') || null;

      setRealmsPackage(realmsPkg?.payload?.items || []);
      setTemplateCollectionsPackage(templateCollectionsPkg?.payload?.items || []);
    } catch (error) {
      console.error('Error loading packages:', error);
    }
  }, [sessionID, username, apiCaller]);

  // Use effect to load the packages when the context is first used
  useEffect(() => {
    loadPackages();
  }, [loadPackages]);
  useEffect(() => {
    if (!username || !sessionID) {
      clearPackages();
    }
  }, [username, sessionID]);

  // Accessor function to get items of a specific type from all user packages
  const getItemsOfType = useCallback((itemType) => {
    return userPackages.flatMap(pkg =>
      pkg.payload.items.filter(item => item.type === itemType)
    );
  }, [userPackages]);

  // Function to get sorted items from Realms, Templates, or specific type
  const getSortedItems = (source, property = 'name', order = 'asc') => {
    let items = [];

    if (source === 'realms' && realmsPackage) {
      items = realmsPackage;
    } else if (source === 'templates' && templateCollectionsPackage) {
      items = templateCollectionsPackage;
    } else {
      items = getItemsOfType(source); // Assumes 'source' is an item type for this case
    }

    return sortItemsByProperty(items, property, order);
  };

  const value = {
    userPackages, // All user packages
    realmsPackage, // Specific Realms package items
    templateCollectionsPackage, // Specific Template Collections package items
    loadPackages, // Expose the load function in case you need to refresh the packages
    getItemsOfType, // Expose the accessor function to get items of a specific type
    getSortedItems, // Expose the function to get sorted items by a specific property
    clearPackages
  };

  return (
    <PackageContext.Provider value={value}>
      {children}
    </PackageContext.Provider>
  );
};


export default PackageContext;
