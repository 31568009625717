
class AccessOBject {
    constructor(incomingData) {
        if (!incomingData.accessId || !incomingData.targetId) {
            throw new Error('AccessObject must have an accessId and targetId');
        }
        try{
            this.accessId = incomingData.accessId;
            this.targetId = incomingData.targetId;
            this.created = new Date(incomingData.created) || '';
            this.updated = new Date(incomingData.updated) || '';
            this.targetType = incomingData.targetType || 'generic';
            this.payload = incomingData.payload || {};
            this.GSI_targetId = incomingData.GSI_targetId || this.accessId;
            this.GSI_accessId = incomingData.GSI_accessId || this.targetId;
        } catch (error) {
            console.error("Error creating RealmObject:", error);
            throw error;
        }
    }
    update(key,value){
        this[key] = value;
        this.updated = new Date().toUTCString();
    }
    getreferenceID(){
        if (this.targetType !== 'realmObject') {
            throw new Error('AccessObject does not have a referenceID');
        }      
        return this.payload.referenceID;
    }
    setReferenceID(value){
        if (this.targetType !== 'realmObject') {
            throw new Error('AccessObject does not have a referenceID');
        }
        this.payload.referenceID = value;
        this.updated = new Date().toUTCString();
    }
    static AllowedTypes = ['realmObject', 'realm','generic','template','package','collection','user','group','tenant'];
}


export default AccessOBject;

/*
  accessId: string;          // Primary Partition Key
    targetId: string;          // Primary Sort Key
    created: string;                           // Creation timestamp
    updated: string;                           // Update timestamp
    targetType: string;                         // Type of object
    payload: Json;                             // Payload containing the record data
    GSI_targetId: string;      // GSI Partition Key (for querying by the secondary object)
    GSI_accessId: string;      // GSI Sort Key (for querying by the primary object)

    [key: string]: any;                        // Additional fields if necessary
    */
