import React, { useState, useEffect } from 'react';
import { Select, Button, Typography, Row, Col, message, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons'; 
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import PortalConfiguration from '../configuration/config';
import UserAccessControl from './UserAccessInput';
import CampaignAccessControl from './CampaignAccessControl';

const { Option } = Select;
const { Text, Title } = Typography;

const ShareTab = () => {
  const { realmObject, updateRealmObject, realmObjectID, allowedAccessors} = useRealmObjectContext();
  const [visibility, setVisibility] = useState('');
  const [publicUrl, setPublicUrl] = useState(''); // State to store the generated public URL

  useEffect(() => {
    // Set initial visibility based on realmObject's publishedLevelACL
    if (realmObject?.publishedLevelACL) {
      setVisibility(realmObject.publishedLevelACL);
      setPublicUrl(`${PortalConfiguration.readerURL}?referencedID=${realmObject.referenceID}`);
    }
  }, [realmObject]);

  const handleChange = async (value) => {
    setVisibility(value);
    updateRealmObject('publishedLevelACL', value);
    if (value === 'PUBLIC') {
      try {
        setPublicUrl(`${PortalConfiguration.readerURL}?referencedID=${realmObject.referenceID}`);

      } catch (error) {
        console.error('Error generating public URL:', error);
      }
    } else {
      // Clear the public URL when visibility is not "PUBLIC"
      setPublicUrl('');
    }
  };

  const handleCopy = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(publicUrl)
        .then(() => {
          message.success('Text copied to clipboard');
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    } else {
      // Fallback method if Clipboard API is not available
      const textArea = document.createElement('textarea');
      textArea.value = publicUrl;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        message.success('Text copied to clipboard from fallback method');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <Space direction='vertical' className=''>
      <Row direction='vertical' className='element-margined-small'>
        <Title level={3} className='custom-title fab-fill-parent'>Published Level</Title>
        <Select
          value={visibility}
          onChange={handleChange}
          className='fab-fill-parent small-select'
          popupClassName="small-select"
        >
          <Option value="PUBLIC">Public</Option>
          <Option value="LIMITED">Limited</Option>
          <Option value="PRIVATE">Private</Option>
        </Select>
  
      {/* Conditionally render Text components based on the selected visibility */}
      {visibility === 'PRIVATE' && (
        <Text className="text-secondary element-margined-small fab-fill-parent">Not Visible to Anyone</Text>
      )}

      {visibility === 'LIMITED' && (
        <Text className="text-secondary element-margined-small fab-fill-parent">Visible to Authorized Users.</Text>
      )}

      {visibility === 'PUBLIC' && (
        <Text className="text-secondary element-margined-small fab-fill-parent">Visible to anyone with the link.</Text>
      )}
      </Row>
      <>
        <Space direction='vertical' className='element-margined-small'>
            <Title level={5} className='custom-title  fab-fill-parent'>Publish to Campaigns</Title>
            <CampaignAccessControl key={realmObjectID} targetId={realmObjectID} visibility={visibility}/>
        </Space>
        <Space direction='vertical' className='element-margined-small'>
            <Title level={5} className='custom-title  fab-fill-parent'>Authorized Users</Title>
            <UserAccessControl key={realmObjectID} targetId={realmObjectID} visibility={visibility}/>
        </Space>
      </>
      {(visibility === 'PUBLIC') && (
        <Space direction='vertical' className='element-margined-small'>
          <Title level={5} id="title-public-url" className='custom-title'>
            Sharable Link
          </Title>
          <Row align="middle">
            <Col>
              <Text id="public-url" className='text-url'>
                {publicUrl}
              </Text>
            </Col>
            <Col>
              <Button 
                type="secondary" 
                onClick={handleCopy} 
                className='secondary-btn element-margined-tiny-left small'
                disabled={!publicUrl} // Disable the button if there is no URL to copy
                icon={<CopyOutlined />} // Add the copy icon here
              />    
            </Col>
          </Row>
        </Space>
      )}
    </Space>
    
  );
};

export default ShareTab;
