import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import { EditOutlined, EyeOutlined , SaveFilled} from '@ant-design/icons';
import MarkdownPreview from '@uiw/react-markdown-preview';
import './css/StoryPresenter.css';
import './css/MarkDownStyles.css';

import {
  BoldItalicUnderlineToggles,
  UndoRedo,
  MDXEditor,
  toolbarPlugin,
  listsPlugin,
  ListsToggle,
  tablePlugin,
  InsertTable,
  BlockTypeSelect,
  headingsPlugin,
  quotePlugin,
  linkPlugin,
  linkDialogPlugin,
  Separator,
  thematicBreakPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './css/StoryPresenter.css';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

const StoryPresenter = ({ fieldKey,  StoryLabel, storySize = 'small-story' }) => {
  const { Text, Title } = Typography;

  // Access the realmObject and updateRealmObject from RealmObjectProvider
  const { realmObject, updateRealmObject } = useRealmObjectContext();

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [localContent, setLocalContent] = useState(""); // Local state for editing

  // Sync content with realmObject when it changes
  useEffect(() => {
    setIsLoading(true);
    setIsEditing(false);
    const initialContent = realmObject?.payload?.[fieldKey] || "";
    setLocalContent(initialContent); // Initialize local content  
    setIsLoading(false);
  }, [realmObject, fieldKey]);

  const toggleEditMode = () => {
    if (isEditing) {
      // User is toggling from edit to view, update the realm object
      updateRealmObject(fieldKey, localContent);
    }
    setIsEditing(!isEditing);
  };

  // Handle content change in the editor
  const handleContentChange = (newValue) => {
    setLocalContent(newValue); // Update local state only
  };

  // Configure the toolbar plugin with all the required features
  const toolbar = toolbarPlugin({
    toolbarContents: () => (
      <>
        <BlockTypeSelect />
        <BoldItalicUnderlineToggles />
        <Separator />
        <ListsToggle />
        <InsertTable />
        <Separator />
        <UndoRedo />
      </>
    ),
  });

  // Include all necessary plugins
  const plugins = [
    toolbar,
    headingsPlugin(),
    quotePlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    listsPlugin(),
    tablePlugin(),
    thematicBreakPlugin(),
  ];

  // Plugins without the toolbar
  const pluginsWithoutToolbar = [
    headingsPlugin(),
    quotePlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    listsPlugin(),
    tablePlugin(),
    thematicBreakPlugin(),
  ];

  return (
    <div className="story-presenter">
      <Button 
        icon={isEditing ? <SaveFilled /> : <EditOutlined />} 
        className="edit-button primary"
        onClick={toggleEditMode} // Handle View/Edit toggle
      />
      <Title className='custom-title' level={5}> {StoryLabel}</Title>
      {!isEditing ? (
        <MarkdownPreview className="markdown-previewer" style={{ border: '1px solid', borderRadius: '8px' }} source={localContent} />
      ) : (
        <MDXEditor
          plugins={plugins} // Apply different plugins based on edit mode
          markdown={localContent} // Use local content for editing
          onChange={handleContentChange} // Handle content changes in local state
          className={storySize + ' custom-editor'} // Apply the storySize as a className
          contentEditableClassName='custom-markdown'
        />
      )}

      {!realmObject?.payload?.[fieldKey] && !isEditing && (
        <Text className="text-highlight text-primary element-margined-small">
          "Press Edit or mosey over to the Foundry"
        </Text>
      )}
    </div>
  );
};

export default StoryPresenter;
