import React from 'react';
import StoryPresenter from './StoryPresenter';
import { Row, Col, Typography } from 'antd';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import './css/PresentationTab.css'; // Import CSS for custom styling
import ImageLoader from './ImageLoader'; // Import ImageLoader component
import useRealmObjectImages from '../hooks/useRealmObjectImages'; // Import custom hook for image management

const { Text } = Typography;

const PresentationTab = () => {
  const { realmObject, realmObjectID, updateRealmObject } = useRealmObjectContext();
  const { addImage } = useRealmObjectImages(); // Other functions removed as they aren't used here.

  const handleImageItemCreation = async (imageItem) => {
    console.log('Image Item Created:', imageItem);

    // Ensure realmObject's images array exists and add the new image
    await updateRealmObject('images', [...(realmObject?.images || []), imageItem]);
    
    // Add image to the custom hook context
    addImage(imageItem, null); // Initialize imageBlob as null
  };

  const handleImageItemDeletion = async (selfload) => {
    console.log('Image Item Deleted:', selfload);

    const GSI_PK_SERVICEID = selfload.serviceID;
    const GSI_SK_SERVICEOBJECTID = selfload.objectID;
    // Ensure realmObject's images array exists and remove the image
    await updateRealmObject('images', 
      (realmObject?.images 
        || []).filter((item) => item.GSI_PK_SERVICEID !== GSI_PK_SERVICEID 
      && item.GSI_SK_SERVICEOBJECTID !== GSI_SK_SERVICEOBJECTID));
  };

  const ImageLoaderParams = {
    handleImageItemCreation: handleImageItemCreation,
    handleImageItemDeletion: handleImageItemDeletion,
  };

  // Determine if the realmObject has images or not
  const hasImages = realmObject && realmObject.payload && realmObject.payload.images && realmObject.payload.images.length > 0;
  const noImageColSpan = 3;
  return (
    <div className="tab-container">
      <Row gutter={[8, 8]}>
        {/* Image Column */}
        <Col
          xs={24}  // Full width on extra small screens
          sm={24}  // Full width on small screens
          md={hasImages ? 12 : noImageColSpan}  // Half width if there are images, less width if no images
          lg={hasImages ? 12 : noImageColSpan}  // Same behavior on larger screens
        >
          {/* ImageLoader Component */}
          {realmObject ? (
            <ImageLoader key={realmObjectID} {...ImageLoaderParams} />
          ) : (
            <Text type="warning">Unable to Load Images for this object...</Text>
          )}
        </Col>

        {/* Text Content Column */}
        <Col
          xs={24}  // Full width on extra small screens
          sm={24}  // Full width on small screens
          md={hasImages ? 12 : 24-noImageColSpan}  // More width for text if there are no images
          lg={hasImages ? 12 : 24-noImageColSpan}  // Same behavior on larger screens
        >
          {/* StoryPresenter for ShortStory */}
          <StoryPresenter
            fieldKey="ShortStory"
            StoryLabel={`Brief Story about ${realmObject?.name}`}
            storySize="old-story"
          />
        </Col>

        {/* Long Story Column */}
        <Col span={24}>
          {/* StoryPresenter for LongStory */}
          <StoryPresenter
            fieldKey="LongStory"
            StoryLabel={`Details about ${realmObject?.name}`}
            storySize="large-story"
          />
        </Col>
      </Row>
    </div>
  );
};

export default PresentationTab;
