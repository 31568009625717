  import React, { useEffect, useState, useRef } from 'react';
  import { Space, Spin, Button, Typography, message, Grid, Input, Tree } from 'antd';
  import { LoadingOutlined, SendOutlined , CaretDownOutlined} from '@ant-design/icons';
  
  import { useAuthContext } from '../contexts/AuthProvider';
  import { useRealmContext } from '../contexts/RealmProvider';
  import { usePackageContext } from '../contexts/PackageProvider';
  import PortalConfiguration from '../configuration/config'; 
  import TemplatesService from '../services/TemplatesService';
  import { sortItemsByProperty } from '../utility/sorting';
  import { useRealmObjectCrud } from '../hooks/useRealmObjectCrud';
  import { useRealmOrder } from '../hooks/useRealmOrder';
  import { useRealmObjectFetching } from '../hooks/useRealmObjectFetching';
  import useTemplateTree from '../hooks/useTemplateTree';  // Import the new hook
  import ApiCaller from '../services/ApiCaller';
  import IconifiedText from './IconifiedText';
  import './css/TemplatePanel.css';

  const { Title } = Typography;
  const { useBreakpoint } = Grid;
  const { Search } = Input;

  const TemplatePanel = () => {
    const [templates, setTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { username, sessionID } = useAuthContext();
    const { realmID } = useRealmContext();
    const { templateCollectionsPackage } = usePackageContext();
    const { getHighestSiblingOrder } = useRealmOrder();
    const screens = useBreakpoint();
    const apiCallerRef = useRef(new ApiCaller(PortalConfiguration.templatesURL));
    const realmObjectApiCaller = new ApiCaller(PortalConfiguration.realmobjectsURL);
    const { createRealmObjectFromTemplate } = useRealmObjectCrud(realmObjectApiCaller, sessionID, username, realmID);
    const { fetchRealmObjectList } = useRealmObjectFetching(realmObjectApiCaller, sessionID, username, ApiCaller.handleApiError);

    // Custom loading indicator
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    // Fetch templates when component mounts or when username changes
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          if (!templateCollectionsPackage || templateCollectionsPackage.length === 0) {
            console.error('No template collections available');
            return;
          }

          const templatesList = await Promise.all(templateCollectionsPackage.map(async (element) => {
            return await TemplatesService.getTemplates(apiCallerRef.current, sessionID, { collectionID: element.name });
          }));

          const flatTemplatesList = templatesList.flat();
          const sortedTemplates = sortItemsByProperty(flatTemplatesList, 'name');
          setTemplates(sortedTemplates);
          setFilteredTemplates(sortedTemplates);
        } catch (error) {
          console.error('Failed to load templates - API call error:', error);
        } finally {
          setLoading(false);
        }
      };

      if (templateCollectionsPackage && templateCollectionsPackage.length > 0) {
        fetchData();
      }
    }, [sessionID, templateCollectionsPackage, username]);

    const handleCreateClick = async (template) => {
      try {
        const newPayload = { ...template.payload, realmOrder: (getHighestSiblingOrder(PortalConfiguration.NoParentID)+10) };
        const newInputObject = { ...template, payload: newPayload };
        await createRealmObjectFromTemplate(newInputObject);
        fetchRealmObjectList(realmID);
        message.success('New creation added successfully');
      } catch (error) {
        message.error('Something unexpected went wrong.' + error);
      }
    }

    const { treeData, expandedKeys, setExpandedKeys } = useTemplateTree(filteredTemplates, searchTerm, handleCreateClick);

    const onExpand = (expandedKeys) => {
      setExpandedKeys(expandedKeys);
    };

    return (
      <Space
        align='top'
        className='left-panel custom-row panel-background-medium fab-panel element-margined-small'
      >
        {screens.xxl && <Title level={2} className='custom-title center-element'>Templates</Title>}
        
        <Search
          placeholder="Search Templates"
          onChange={(e) => setSearchTerm(e.target.value)}
          className='fab-fill-parent custom-search'
        />

        <Spin spinning={loading} indicator={antIcon}>
          <Tree
            treeData={treeData}
            defaultExpandAll
            selectable={false}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            switcherIcon={<CaretDownOutlined />}
          />
        </Spin>
      </Space>
    );
  };

  export default TemplatePanel;
