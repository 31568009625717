import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginForm from '../components/loginform';
import SessionMain from '../components/SessionMain';
import RealmProvider from '../contexts/RealmProvider';
import RealmObjectProvider from '../contexts/RealmObjectProvider';
import CampaignProvider from '../contexts/CampaignProvider';
import NotAuthorized from '../components/NotAuthorized';


const AppRoutes = () => {
  return (
    <Routes>
      {/* Use JSX for Session routes */}
      <Route 
        path="/session" 
        element={
          <RealmProvider>
            <RealmObjectProvider>
              <CampaignProvider>
                <SessionMain />
              </CampaignProvider>
            </RealmObjectProvider>
          </RealmProvider>
        } 
      />     
      {/* Other routes */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/notauthorized" element={<NotAuthorized/>} />
    </Routes>
  );
};

export default AppRoutes;
