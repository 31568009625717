import React from 'react';
import { Button, Space, Tooltip, Modal } from 'antd';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import './css/RealmObject.css';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

const RealmObjectActionBar = ({ isDisabled, handleDelete, handleSave }) => {
  const { realmObjectEdited, realmObject } = useRealmObjectContext();

  // Function to show the confirmation modal
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete "' + realmObject.name + '?"',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'info',
      cancelText: 'Cancel',
      onOk() {
        handleDelete(); // Call the delete handler when the user confirms
      },
    });
  };

  return (
    <Space direction="horizontal" className="realm-object-button-bar">
      <Tooltip title="Save">
        <Button
          icon={<SaveOutlined />}
          shape="circle"
          size="large"
          onClick={handleSave}
          disabled={!realmObjectEdited || isDisabled}
        />
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          icon={<DeleteOutlined />}
          shape="circle"
          size="large"
          onClick={showDeleteConfirm} // Trigger the confirmation modal when clicked
          disabled={isDisabled}
        />
      </Tooltip>
    </Space>
  );
};

export default RealmObjectActionBar;
