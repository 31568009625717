import React, { useEffect, useState } from 'react';
import IconDisplay from './IconDisplay'; // Assuming the correct path
import { Button, Space, Typography, Row, Col, Card } from 'antd'; // Ant Design for buttons
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; // Icons for navigation
import './css/IconSelector.css'; // Assuming this file exists for styles

const IconSelector = ({ onSelect, ImageTable, fileIndex, iconIndex }) => {
  const [localIconIndex, setLocalIconIndex] = useState(iconIndex); // Icon index state
  const [localFileIndex, setLocalFileIndex] = useState(fileIndex); // File index state
  const [currentImage, setCurrentImage] = useState(ImageTable[fileIndex]); // Current image object
  const [totalIcons, setTotalIcons] = useState(
    ImageTable[fileIndex].iconsPerRow * ImageTable[fileIndex].iconsPerColumn
  ); // Total icons in the current file
  const { Text, Title } = Typography;
  
  // Handle next/previous file navigation
  const handleNextFile = () => {
    if (localFileIndex < ImageTable.length - 1) {
      const newValue = Number(localFileIndex) + 1;
      setCurrentImage(ImageTable[newValue]); // Update current image object
      setTotalIcons(
        ImageTable[newValue].iconsPerRow * ImageTable[newValue].iconsPerColumn
      );
      setLocalFileIndex(newValue);
      setLocalIconIndex(0); // Reset icon index when switching files
    }
  };

  const handlePreviousFile = () => {
    if (localFileIndex > 0) {
      const newValue = Number(localFileIndex) - 1;
      setCurrentImage(ImageTable[newValue]);
      setTotalIcons(
        ImageTable[newValue].iconsPerRow * ImageTable[newValue].iconsPerColumn
      );
      setLocalFileIndex(newValue);
      setLocalIconIndex(0); // Reset icon index when switching files
    }
  };

  // Handle next/previous icon navigation
  const handleNextIcon = () => {
    if (localIconIndex < totalIcons - 1) {
      setLocalIconIndex(Number(localIconIndex) + 1);
    }
  };

  const handlePreviousIcon = () => {
    if (localIconIndex > 0) {
      setLocalIconIndex(Number(localIconIndex) - 1);
    }
  };

  // Handle final selection (pass selected file and icon index to parent)
  const handleSelect = () => {
    console.log('Selected icon:', localFileIndex, localIconIndex);
    onSelect(localFileIndex, localIconIndex );
  };

  const removeExtension = (filename) => {
    return filename.split('.').slice(0, -1).join('.');
    };

  useEffect(() => {
    setLocalIconIndex(iconIndex);
    setLocalFileIndex(fileIndex);
    setCurrentImage(ImageTable[fileIndex]);
    setTotalIcons(
      ImageTable[fileIndex].iconsPerRow * ImageTable[fileIndex].iconsPerColumn
    );
  }, [fileIndex, iconIndex]);

  // Calculate the indices for the next and previous 5 icons
  const getPreviewIcons = (start, end) => {
    const indices = [];
    for (let i = start; i <= end; i++) {
      if (i >= 0 && i < totalIcons) {
        indices.push(i);
      }
    }
    return indices;
  };

  
  const previousIcons = getPreviewIcons(localIconIndex - 5, localIconIndex - 1);
  const nextIcons = getPreviewIcons(Number(localIconIndex) + 1, Number(localIconIndex) + 5);

  return (
    <Row direction='vertical' className="icon-selector"> 
        <Col align="center">
            <Row justify="center" className=''> {/* Add Row with horizontal layout */}
                <Space direction="vertical" align="center" className='element-margined-nill'>
                        <Text className='text-secondary element-margined-nill'>{removeExtension(currentImage.src)}</Text> {/* Show the current image's filename */}
                </Space>
            </Row>
            
        </Col>
        <Col align="center">
            {/* Card container for the layout */}
           
            <Row justify="center" className=''>
                <Space className="icon-card" direction="vertical" align="center"> 
                    <Space className="file-navigation" direction='horizontal' align="center">
                        <Button
                            onClick={handlePreviousFile}
                            disabled={localFileIndex === 0}
                            icon={<LeftOutlined />}
                            className='secondary'
                        />
                        <Button
                            onClick={handleNextFile}
                            disabled={localFileIndex === ImageTable.length - 1}
                            icon={<RightOutlined />}
                            className='secondary'
                        />
                    </Space>
                </Space>
            </Row>
        </Col>
        <Col>
            <Row justify="center" className=''> {/* Add Row with horizontal layout */}
                <Text className='text-secondary element-margined-top-small'>Previous {previousIcons.length} Icons</Text>
            </Row>
            <Row gutter={[8, 8]} justify="start" className='element-padded-small'> {/* Add Row with horizontal layout */}
                {previousIcons.map((iconIdx) => (
                <Col key={iconIdx}>
                    <IconDisplay
                    imageTableIndex={localFileIndex}
                    iconIndex={iconIdx}
                    displayWidth={30}
                    displayHeight={30}
                    passOnClick={() => setLocalIconIndex(iconIdx)}
                    />
                </Col>
                ))}
            </Row>
        </Col>
       
        <Col  direction='horizontal'>
            {/* Display the icon */}
            <IconDisplay     
            imageTableIndex={localFileIndex}
            iconIndex={localIconIndex}
            displayWidth={65} // You can adjust the display size
            displayHeight={65}
            />
        </Col>
        <Col>
            {/* Card container for the layout */}
            <Space className="icon-card" direction="vertical" align="center"> 
                <Space className="file-navigation" direction='vertical' align="center">
                    <Space className="file-navigation" direction='horizontal'>
                        <Button
                            onClick={handlePreviousIcon}
                            disabled={localIconIndex === 0}
                            icon={<LeftOutlined  />}
                            className='secondary'
                        />
                        <Button
                            onClick={handleNextIcon}
                            disabled={localIconIndex === totalIcons - 1}
                            icon={<RightOutlined  />}
                            className='secondary'
                        />
                    </Space>
                    <Text className='text-secondary'>Icon {Number(localIconIndex) +1  } of {totalIcons}</Text>
                </Space>
                <Space  className="icon-info">
                    <Space  className="icon-preview" direction='vertical' align="center">
                        <Text className='text-secondary'>Next {nextIcons.length} Icons</Text>
                        <Space  className="preview-row">
                            {nextIcons.map((iconIdx) => (
                                <IconDisplay
                                key={iconIdx}
                                imageTableIndex={localFileIndex}
                                iconIndex={iconIdx}
                                displayWidth={30}
                                displayHeight={30}
                                passOnClick={() => setLocalIconIndex(iconIdx)}
                                />
                            ))}
                       </Space>
                    </Space>
                </Space>
            </Space>
        </Col>
    
      {/* Select button to confirm the selection */}
      <Button
        type="primary"
        onClick={handleSelect}
        style={{ marginTop: '10px' }}
        className='primary'
      >
        Select Icon
      </Button>

  
    </Row>
  );
};

export default IconSelector;
