import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import ApiCaller from '../services/ApiCaller';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import PortalConfiguration from '../configuration/config';
import CampaignFabricatorAuthTokens from '../configuration/AuthorizationTokens';
import useAuthorization from '../hooks/useAuthorization';
import { usePackageContext } from './PackageProvider';

const AuthContext = createContext();

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [username, setUsername] = useState(localStorage.getItem('username') || "");
    const [sessionID, setSessionID] = useState(localStorage.getItem('sessionID') || '');
    const [sessionIDChecked, setSessionIDChecked] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);  // Initially false until checked
    const [authorizationProfile, setAuthorizationProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [userPermissionLevel, setUserPermissionLevel] = useState(CampaignFabricatorAuthTokens.ANONYMOUS);
    const navigate = useNavigate();
    const { checkAuthorization } = useAuthorization(); 
    const [AccessList, setAccessList] =useState();

    // Initialize the API Caller
    const apiCaller = useState(new ApiCaller(PortalConfiguration.usersettingsURL))[0];

    const saveToLocalStorage = (key, value) => {
        if (value) {
            localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    };

    useEffect(() => {
        if (userPermissionLevel !== CampaignFabricatorAuthTokens.PLAYER)
            {saveToLocalStorage('username', username);}
        else {
            saveToLocalStorage('username', null);
        }
    }, [username]);

    useEffect(() => {
        if (userPermissionLevel !== CampaignFabricatorAuthTokens.PLAYER) {
            saveToLocalStorage('sessionID', sessionID);
        } else {
            saveToLocalStorage('sessionID', null);
        }
    }, [sessionID]);

    const login = useCallback(async (inputUsername, password) => {
        if (loading) return;

        setLoading(true);
        setError(null);

        try {
            const result = await apiCaller.callApi({
                authorization: '',
                userID: inputUsername,
                service: '/login',
                method: 'PUT',
                headers: {
                    'applicationID': '08-08-2024AOCF',
                },
                body: { username: inputUsername, password, applicationID: '08-08-2024AOCF' },
            });

            if (result.message === 'Login successful') {
                setUsername(inputUsername);
                setSessionID(result.sessionID);
                setSessionIDChecked(Date.now());
                setAuthorizationProfile(result.authorizationProfile);
                setLoggedIn(true);
            } else {
                throw new Error(result.message || 'Login failed');
            }
        } catch (err) {
            ApiCaller.handleApiError(err);
            setLoggedIn(false);
        } finally {
            setLoading(false);
        }
    }, [apiCaller, loading, navigate]);

    const changePassword = useCallback(async (newPassword, newPasswordVerification) => {
        if (loading) return;

        setLoading(true);
        setError(null);
        var result;
        try {
            if (newPassword !== newPasswordVerification)
            {
                result = {message: 'Passwords do not match'};
            } else
            {
                result = await apiCaller.callApi({
                    authorization: sessionID,
                    userID: username,
                    service: '/user/changepassword',
                    method: 'PUT',
                    headers: {
                        'applicationID': PortalConfiguration.defaultApplicationID,
                    },
                    body: {userdata: { username, password: newPassword} },
                });
            }
            if (result.message === 'Password updated successfully') {
                message.success('Password changed successfully');
            } else {
                message.error('Password change failed')
                throw new Error(result.message || 'Password change failed');
            }
        } catch (err) {
            ApiCaller.handleApiError(err);
        } finally {
            setLoading(false);
        }
    }, [apiCaller, loading, sessionID, username]);

    const logout = useCallback(() => {
        setSessionID('');
        setUsername('');
        setUserPermissionLevel(null)
        setLoggedIn(false);
        navigate('/login');
    }, [navigate]);


    const checkSession = useCallback(async () => {
        if (!sessionID) return false;
        if (sessionID && sessionIDChecked > Date.now() - 3000) return true; // Check every 3 seconds

        setLoading(true);
        setError(null);

        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                userID: username,
                service: '/checkSession',
                method: 'PUT',
                headers: {
                    'applicationID': '08-08-2024AOCF',
                },
                body: { sessionID }
            });

            if (result.message === 'Session valid') {
                setLoggedIn(true);
                setUsername(result.usersession.userId || username); // Set username if returned, otherwise keep existing
                setAuthorizationProfile(result.usersession.authorizationProfile);
                setSessionIDChecked(Date.now());
                return true;
            } else {
                throw new Error('Session invalid');
            }
        } catch (err) {
            ApiCaller.handleApiError(err);
            setLoggedIn(false);
            setSessionID('');
            setUsername('');
            return false;
        } finally {
            setLoading(false);
        }
    }, [sessionID, sessionIDChecked, apiCaller, username, navigate]);

    const isSessionValid = useCallback(async () => {
        
        return await checkSession();
    }, [checkSession]);

    useEffect(() => {
        if (loggedIn) {
            navigate('/session');
        }
    }, [loggedIn]);

    const checkForUserName = useCallback(async (pUsername) => {
        try {
            // Validate session
            if (!sessionID || !(await checkSession())) {
                throw new Error('Session is invalid or expired');
            }
    
            // Make API call to check for username
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/checkusername',
                method: 'GET',
                params: { username: pUsername },
                headers: {
                    'applicationID': '08-08-2024AOCF',
                },
                body: null
            });
    
            // Check if username exists
            if (result && result.exists) {
                return true; // Username exists
            }
    
            return false; // Username does not exist
        } catch (error) {
            console.error("Error checking username:", error);
            throw new Error(`Failed to check username: ${error.message || 'Unknown error'}`);
        }
    }, [apiCaller, checkSession, sessionID]);
    

    useEffect(() => {
        async function fetchData() {
            if (authorizationProfile) {
                const permissionLevels = [
                    { token: CampaignFabricatorAuthTokens.REALM_MASTER, level: 'REALM_MASTER' },
                    { token: CampaignFabricatorAuthTokens.GAME_MASTER, level: 'GAME_MASTER' },
                    { token: CampaignFabricatorAuthTokens.ADVENTURER, level: 'ADVENTURER' },
                    { token: CampaignFabricatorAuthTokens.PLAYER, level: 'PLAYER' }
                ];
    
                for (const { token, level } of permissionLevels) {
                    if (await checkAuthorization(sessionID, token, authorizationProfile)) {
                        setUserPermissionLevel(token);
                        console.log('User permission level:', level, token, sessionID);
                        if (sessionID && level !== 'PLAYER') 
                        {
                            navigate('/session');
                        }
                        else if (sessionID){
                            navigate('/notauthorized');
                        }
                        break; // Exit the loop after setting the permission level
                    }
                }
            }
        }
        fetchData();
    }, [checkAuthorization, authorizationProfile, sessionID]);
    
    // Check the session on initial load
    useEffect(() => {
        if (username && sessionID) {
            checkSession();
            
        }
    }, [username, sessionID, checkSession]);

    return (
        <AuthContext.Provider value={{
            username,
            loggedIn,
            sessionID,
            loading,
            error,
            login,
            logout,
            checkSession,
            isSessionValid,  // Add isSessionValid to the context value
            changePassword,
            AccessList,
            setAccessList,
            userPermissionLevel,
            checkForUserName
        }}>
            {children}
        </AuthContext.Provider>
    );
};
