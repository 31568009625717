class ApiCaller {
  constructor(domain) {
    this.domain = domain;
  }

  // Handle API errors and log them with specific action context
  static handleApiError(error, action) {
    console.error(`Failed to ${action}: ${error.message}`);
  }

  // API call method with error handling for all bad statuses
  async callApi({ authorization, service, method = 'GET', headers = {}, body = null, params = {} }) {
    // Ensure that authorization is provided for non-GET requests except login and session check
    if (!authorization && method !== 'GET' && service !== '/login' && service !== '/checkSession') {
      throw new Error('User not authenticated. Only GET requests are allowed.');
    }

    // Construct the URL with query parameters
    const url = new URL(`${this.domain}${service}`);
    Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));

    // Set up the request options
    const options = {
      method,
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorization}`,
        'x-session-id': authorization,
        ...headers,
      }),
      mode: 'cors',
    };

    // Add the request body if it's provided
    if (body) {
      options.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(url, options);

      // Handle non-OK responses (4xx or 5xx)
      if (!response.ok) {
        let errorDetails = {};
        try {
          errorDetails = await response.json(); // Try to parse the error response body
        } catch (parseError) {
          console.error('Error parsing error response:', parseError);
        }

        // Throw an error for any bad status code between 400 and 504
        const error = new Error(`HTTP error! Status: ${response.status}`);
        error.statusCode = response.status;
        error.details = errorDetails;
        throw error;
      }

      // Check the content type of the response
      const contentType = response.headers.get('Content-Type');
      if (!contentType) {
        throw new Error('No Content-Type header found');
      }

      // Handle JSON responses
      if (contentType.includes('application/json')) {
        return await response.json();
      } else {
        throw new Error(`Unexpected response format: ${contentType}`);
      }
    } catch (error) {
      console.error('Error making API call:', error);
      throw error;  // Re-throw the error to propagate it to the caller
    }
  }
}

export default ApiCaller;
