import React from 'react';
import { Avatar, Flex, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons'; // Example icon
import './css/UserStatusAvatar.css'; // Custom styles
import { useAuthContext} from '../contexts/AuthProvider'; // Import the useAppContext function from the appropriate module

const UserStatusAvatar = ({ showDrawer }) => {
    const { Text } = Typography;
    const { username } = useAuthContext(); // Access the username from the AppContext
    return (
        <Flex className="user-status-container">
            <Avatar
                size="large" // You can adjust the size of the avatar
                icon={<UserOutlined />} // Replace this with text or an image if needed
                onClick={showDrawer} // Attach the event handler
                className="user-status-avatar" // Custom class for styling
                style={{ cursor: 'pointer' }} // Add styling like background color, etc.
            />
            <Text className="user-status-text">{username}</Text>
        </Flex>
    );
};

export default UserStatusAvatar;
