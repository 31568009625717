import React from 'react';
import { Typography } from 'antd';
import './css/EmptyControlContent.css';

const { Text } = Typography;

const EmptyControlContent = ({ emptyText = "Nothing yet? Let's get started!" }) => {
  return (
      <Text className="empty-control-content" type="secondary">{emptyText}</Text>
  );
};

export default EmptyControlContent;
