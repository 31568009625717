import React from 'react';
import { List, Typography, Button, Tooltip, Row, Col } from 'antd';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import './css/AttributeCard.css'; // Assuming you'll place the custom styles here

const { Text } = Typography;

const AttributeCard = ({ attribute, handleDelete }) => {
  return (
    <List.Item className="attribute-card-item">
      <Row justify="space-between" align="middle" style={{ width: '95%' }}>
        <Col>
          <Text
            id={`${attribute.Name}-value-input`}
            className="text-primary element-margined-small-left"
          >
            {attribute.Value || "No value"}
          </Text>
        </Col>
        <Col flex="auto" className='element-margined-small-left'>
          <Tooltip title={attribute.Notes || ""}>
            <InfoCircleOutlined className="question-card-icon" />
          </Tooltip>
        </Col>
        <Col>
          <Tooltip title="Delete this attribute">
            <Button 
              icon={<DeleteOutlined />} 
              type="text" 
              onClick={() => handleDelete(attribute.Name)} 
              className="attribute-card-delete-button" 
            />
          </Tooltip>  
        </Col>
      </Row>
    </List.Item>
  );
};

export default AttributeCard;
