import React, { createContext, useState, useContext, useEffect, useCallback, useRef, useMemo, useReducer } from 'react';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config'; // Configuration with API URLs
import { useRealmContext } from './RealmProvider';
import { useAuthContext } from './AuthProvider';
import { useRealmObjectCrud } from '../hooks/useRealmObjectCrud'; // Custom hook for CRUD operations
import { useLinkManagement } from '../hooks/useLinkManagement'; // Custom hook for link management
import APIContext from '../types/APIContext';

const RealmObjectContext = createContext();

export const useRealmObjectContext = () => {
  const context = useContext(RealmObjectContext);
  if (!context) {
    throw new Error('useRealmObjectContext must be used within a RealmObjectProvider');
  }
  return context;
};

export const RealmObjectProvider = ({ children }) => {

  const realmObjectListReducer = (state, action) => {
    switch (action.type) {
      case 'SET_LIST':
        return action.payload;
      case 'ADD_OBJECT':
        return [...state, action.payload];
      case 'REMOVE_OBJECT':
        return state.filter(obj => obj.RealmObjectID !== action.payload);
      case 'UPDATE_OBJECT':
        return state.map(obj =>
          obj.RealmObjectID === action.payload.RealmObjectID ? action.payload : obj
        );
      default:
        return state;
    }
  };

  const [realmObjectID, setRealmObjectID] = useState(null); // Manages the current realm object ID
  const [realmObject, setRealmObjectPrivate] = useState(null); // Holds the current realm object
  const [realmObjectList, dispatchRealmObjectList] = useReducer(realmObjectListReducer, []); // Holds the list of all realm objects
  const [incomingRealmObjectLinks, setIncomingRealmObjectLinks] = useState([]); // Incoming links for the object
  const [outgoingRealmObjectLinks, setOutgoingRealmObjectLinks] = useState([]); // Outgoing links for the object
  const [realmObjectEdited, setRealmObjectEdited] = useState(false); // Tracks if the realm object has been edited
  const [realmobjectDragTarget, setRealmObjectDragTarget] = useState(null); // Tracks the realm object being dragged
  const [allowedAccessorsObjectId, setAllowedAccessorsObjectId] = useState(null); // Tracks the realm object API caller
  const [allowedCampaigns, setAllowedCampaigns] = useState([]); // Tracks the realm object API caller
  const [allowedAccessors, setRealAllowedAccessors] = useState([]); // Tracks the realm object API caller
  const [Images, setImages] = useState([]); // Tracks the realm object API caller
  
  
  const { realmID } = useRealmContext(); // Get realmID from RealmContext
  const { username, sessionID } = useAuthContext(); // Get auth info from AuthContext

  const [lastSavedTime, setLastSavedTime] = useState(Date.now());
  const [saveTimeout, setSaveTimeout] = useState(null); 
  // Create a ref to store the latest realmObject

  const setAllowedAccessors = (allowedAccessors) => {
    setRealAllowedAccessors(allowedAccessors);
  }

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isLoadingObject: false,
    isLoadingItem: false,
    isLoadingList: false,
    isFabricating: false,
    isFetchingLinks: false,
  });

  // Initialize API callers
  const apiCaller = useMemo(() => new ApiCaller(PortalConfiguration.realmobjectsURL), []);
  const ApiContext = useMemo(() => new APIContext(apiCaller, sessionID, realmID, ApiCaller.handleApiError), [apiCaller, sessionID, realmID]); // utility hook to store certain states and actions
  const linksApiCaller = useMemo(() => new ApiCaller(PortalConfiguration.linkURL), []);
  const LinksApiContext = useMemo(() => new APIContext(linksApiCaller, sessionID, realmID, ApiCaller.handleApiError), [linksApiCaller, sessionID, realmID]); // utility hook to store certain states and actions
  const AccessApiCaller = useMemo(() => new ApiCaller(PortalConfiguration.accessControlURL), []);


  useEffect(() => {
    setAllowedAccessorsObjectId(realmObjectID);
  }, [allowedAccessors]);

  const checkAllowedAccessors = useCallback(async () => {
    if (!realmObjectID && realmObjectID !== allowedAccessorsObjectId) {
      return false;
    }
    return true;
  }, [realmObjectID, allowedAccessorsObjectId]);

  const setRealmObject = (realmObject) => {
    setRealmObjectPrivate(realmObject);    
  }

  const setRealmObjectList = (realmObjectList) => {
    dispatchRealmObjectList({ type: 'SET_LIST', payload: realmObjectList });
  }
  // Use custom hooks for state and actions
  // takes care of backend calls.
  const { updateRealmObjectValue, saveRealmObject} = 
  useRealmObjectCrud(apiCaller, sessionID, username, realmID); // Custom hook for CRUD operations
  const { fetchLinks } = useLinkManagement()
   
  const getLoading = useCallback((key) => loadingState[key], [loadingState]);
  const setLoading = useCallback((key, value) => {
    if (key === 'isLoadingItem') {
      setLoading('isLoadingObject', value);
    }
    setLoadingState(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const updateRealmObject = useCallback(async (key, value) => {
    if (key === 'images' )
      console.log('Updating realm object', key, value);

    setRealmObject(updateRealmObjectValue(realmObject, key, value));
    setRealmObjectEdited(true);
  }, [realmObject, updateRealmObjectValue]);
 
  const updateLinkedObjects = useCallback(async () => { 
      if (!realmObject) {
        return;
      }
      
      try {
        setLoading('isFetchingLinks', true);
        const { incomingLinks, outgoingLinks } = await fetchLinks(realmObject.RealmID,realmObject.RealmObjectID,LinksApiContext);
        setIncomingRealmObjectLinks(incomingLinks);
        setOutgoingRealmObjectLinks(outgoingLinks);

      } catch (error) {
        ApiCaller.handleApiError("Error updating linked objects.");
        setIncomingRealmObjectLinks([]);
        setOutgoingRealmObjectLinks([]);
      }
      finally 
      {
        setLoading('isFetchingLinks', false);
      }
    }, [realmObject, fetchLinks, LinksApiContext, setLoading]);
    
    const objectsWithinDegreesOfSeparation = useCallback((degreesOfSeparation = 1) => {
      if (!realmObject) {
        return null; // If there's no current realmObject, return null
      }
      if (degreesOfSeparation < 1) {
        return null; // If degreesOfSeparation is less than 1, return null
      }
      const parentID = realmObject.parentID;
      const linkedObjectIDSet = new Set([
        ...incomingRealmObjectLinks.map(link => link.sourceObjectID),
        ...outgoingRealmObjectLinks.map(link => link.destinationObjectID)
      ]);
    
      // Single filter operation
      const objectsWithinSeparation = realmObjectList.filter(item => {
        if (!item.payload || !item.payload.ShortStory || item.payload.ShortStory === '' )
        {
          return false;
        }
        // Parent: If the item's ID is the parentID of the current realmObject
        if (item.RealmObjectID === parentID) {
          return true;
        }
        // Siblings: Same parentID as the current realmObject, but not the realmObject itself
        if (item.parentID === parentID && item.RealmObjectID !== realmObject.RealmObjectID) {
          return true;
        }
        // Children: The item's parentID matches the current realmObject's RealmObjectID
        if (item.parentID === realmObject.RealmObjectID) {
          return true;
        }
        // Linked objects: The item's ID is found in the linkedObjectIDSet
        if (linkedObjectIDSet.has(item.RealmObjectID)) {
          return true;
        }
        return false;
      });
    
      return objectsWithinSeparation;
    }, [realmObject, incomingRealmObjectLinks, outgoingRealmObjectLinks, realmObjectList]);
   
      // Auto-save logic - Save if `PortalConfiguration.autoSaveInterval` has passed since the last edit
      useEffect(() => {
        const savingStuff = async () => {
          console.log('realmObjectEdited changed. Checking for auto-save', realmObjectEdited);
          if (realmObjectEdited) {
              if (saveTimeout) {
                  clearTimeout(saveTimeout); 
              }

              const timeSinceLastSave = Date.now() - lastSavedTime;
              
              const autoSaveInterval = PortalConfiguration.autoSaveInterval || 5000;  // Default to 5000ms if not set
              console.log('Time since last save:', timeSinceLastSave);
              if (timeSinceLastSave >= autoSaveInterval) {
                  // If the configured interval has passed since last save, save immediately
                  await saveRealmObject(realmObject); 
                  setLastSavedTime(Date.now()); 
                  setRealmObjectEdited(false); 
                  autoSavefetchRealmObjectList(realmID);
              } else {
                  // Otherwise, set a timeout to save after the remaining time
                  const timeoutId = setTimeout(async () => {
                     await saveRealmObject(realmObject); 
                      setLastSavedTime(Date.now()); 
                      setRealmObjectEdited(false);
                      autoSavefetchRealmObjectList(realmID); 
                  }, autoSaveInterval - timeSinceLastSave);
                  setSaveTimeout(timeoutId); // Save the timeout so we can clear it later if necessary
              }
          }
        };
        savingStuff();
    }, [realmObjectEdited]);

    const autoSavefetchRealmObjectList = useCallback(async (realmId) => {
      try {
        const response = await apiCaller.callApi({
          userID: username,
          service: '', // Replace with your endpoint
          method: 'GET',
          authorization: sessionID,
          params: { RealmID: realmId },
        });
  
        if (Array.isArray(response)) {
          setRealmObjectList(response); // Set the realm object list
        } else {
          setRealmObjectList([]);
        }
      } catch (error) {
        setRealmObjectList([]);
      }
    }, [setLoading, apiCaller, username, sessionID]);
    
  return (
    <RealmObjectContext.Provider
      value={{
        realmObject,
        realmObjectEdited,
        setRealmObjectEdited,
        realmObjectID,
        realmObjectList,
        incomingRealmObjectLinks, setIncomingRealmObjectLinks,
        outgoingRealmObjectLinks, setOutgoingRealmObjectLinks,  
        updateLinkedObjects,      
        updateRealmObject,
        realmobjectDragTarget, 
        setRealmObjectDragTarget,
        setRealmObjectID,
        setRealmObject,
        setRealmObjectList,
        objectsWithinDegreesOfSeparation,
        realmObjectAPICaller: apiCaller,
        getLoading, 
        setLoading,
        isLoading: loadingState.isLoading,
        isLoadingObject: loadingState.isLoadingObject,
        isLoadingItem: loadingState.isLoadingObject,
        isLoadingList: loadingState.isLoadingList,
        isFabricating: loadingState.isFabricating,
        isFetchingLinks: loadingState.isFetchingLinks,
        allowedAccessors, 
        setAllowedAccessors,
        allowedCampaigns, setAllowedCampaigns,
        Images, setImages,
      }}
    >
      {children}
    </RealmObjectContext.Provider>
  );
};

export default RealmObjectProvider;
