// SessionMain.js

import React, { useState, useEffect } from 'react';
import { Layout, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import RealmLayout from './RealmLayout';  // Updated import
import { useRealmContext } from '../contexts/RealmProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import { RealmObjectManager } from '../managers/RealmObjectManager';

const { Title } = Typography;
const { Header, Footer } = Layout;

const SessionMain = () => {
  const [leftDrawerVisible, setLeftDrawerVisible] = useState(false);
  const { loggedIn, isSessionValid } = useAuthContext();
  const { realmID } = useRealmContext();
  const navigate = useNavigate();

  const showLeftDrawer = () => setLeftDrawerVisible(true);
  const closeLeftDrawer = () => setLeftDrawerVisible(false);

  useEffect(() => {
    const checkSessionValidity = async () => {
      if (!loggedIn) {
        const isValid = await isSessionValid();
        if (!isValid) {
          navigate('/login');
        }
      }
    };
    checkSessionValidity();
  }, [loggedIn, isSessionValid, navigate]);

  console.log('SessionMain: realmID:', realmID);

  return (
    <>
      <RealmObjectManager />

      <Layout className='transparent-background'>
        <Header className='site-header'>
          <SiteHeader />
        </Header>
        
        <RealmLayout
          realmID={realmID}
          leftDrawerVisible={leftDrawerVisible}
          showLeftDrawer={showLeftDrawer}
          closeLeftDrawer={closeLeftDrawer}
        />
        <Footer className='main-background'>
          <Title level={5} className='custom-title center-element'>
            Copyright (C) Actual Outcomes LLC 2024
          </Title>
        </Footer>
      </Layout>
    </>
  );
};

export default SessionMain;
