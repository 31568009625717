import PortalConfiguration from "../configuration/config";
import ApiCaller from "./ApiCaller";

class Fabrication {

  static async randomText(apiCaller,sessionID, realmID, userID, textDescription) {
    let response;
    console.log('fabricate method called');
    try{
      response = await apiCaller.callApi({
        authorization: sessionID,
        userID: userID,
        service: '/ai/random',
        method: 'POST',
        headers: { },
        params: {
          UserId: userID,
          AccountID: PortalConfiguration.defaultAccountID,
        },
        body: { Description: textDescription },
      });
    return response;
    }
    catch (error) {
      console.error('Error in randomText method:', error);
      throw error;
    }
  }

  static async fabricate(apiCaller,sessionID, realmObject, relatedObjects, userID,realmID) {
    try {
      let response;
      if (Array.isArray(relatedObjects) && relatedObjects.length === 0)
      {
        console.log('fabricate method called');
        response = await apiCaller.callApi({
          authorization: sessionID,
          userID: userID,
          service: '/ai',
          method: 'POST',
          headers: { },
          params: {
            UserId: userID,
            AccountID: PortalConfiguration.defaultAccountID,
          },
          body: realmObject,
        });
      }
      else {
        console.log('fabricate method called');
        response = await apiCaller.callApi({
          authorization: sessionID,
          userID: userID,
          service: '/ai/kevinbacon',
          method: 'POST',
          headers: { },
          params: {
            UserId: userID,
            AccountID: PortalConfiguration.defaultAccountID,
          },
          body: {RealmObject: realmObject, RelatedObjects: relatedObjects},
        });
      }

      return response;
     
    } catch (error) {
      console.error('Error in fabricate method:', error);
      throw error;
    }
  }
}

export default Fabrication;
