import React from 'react';
import { Typography, List, Spin } from 'antd';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import LinkDisplay from './LinkDisplay';
import EmptyControlContent from './EmptyControlContent'; // Import your custom EmptyComponent
import './css/LinksPanel.css';

const { Title } = Typography;

const LinksPanel = () => {
  const {
    incomingRealmObjectLinks,
    outgoingRealmObjectLinks,
    realmobjectDragTarget,
    isFetchingLinks
  } = useRealmObjectContext();

  return (
    <Spin spinning={isFetchingLinks} size="large"> {/* Adjusted Spinner */}
      <div className={`links-panel ${realmobjectDragTarget ? 'droppable-border' : ''}`}>
        <Title level={1} className="custom-title center-element">Links</Title>

        <Title level={4} className="text-primary center-element element-margined-top-small">To</Title>
        <List
          className="panel-small"
          dataSource={outgoingRealmObjectLinks}
          renderItem={item => (
            <List.Item className="list-item-compact">
              <LinkDisplay item={item} type='destination' />
            </List.Item>
          )}
          locale={{ emptyText: isFetchingLinks ? <div></div> : <EmptyControlContent /> }} // Custom empty state
        />

        <Title level={4} className="text-primary center-element element-margined-top-small">From</Title>
        <List
          className="panel-small"
          dataSource={incomingRealmObjectLinks}
          renderItem={item => (
            <List.Item className="list-item-compact">
              <LinkDisplay item={item} type='source'/>
            </List.Item>
          )}
          locale={{ emptyText: isFetchingLinks ? <div></div> : <EmptyControlContent /> }} // Custom empty state
        />
      </div>
    </Spin>
  );
};

export default LinksPanel;
