import React, { createContext, useState, useContext, useEffect } from 'react';
import { usePackageContext } from './PackageProvider';
import { fetchRealmCampaigns } from '../hooks/useCampaignService';
import { useAuthContext } from './AuthProvider';

const RealmContext = createContext();

export const useRealmContext = () => {
 
  const context = useContext(RealmContext);
  if (!context) {
    throw new Error('useRealmContext must be used within a RealmProvider');
  }
  return context;
};

export const RealmProvider = ({ children }) => {
  const {realmsPackage} = usePackageContext();

  // Initialize realmID from localStorage if it exists
  const [realmID, setRealmID] = useState(() => {
    let realm = localStorage.getItem('realmID'); 
    if (realmsPackage)
      {
        if (realmsPackage && realmsPackage.length > 0)
        {
          if (!realmsPackage.find(pkg => pkg.name === realm))
          {
            return realmsPackage[0].name;
          } else {
            return realm;
          }
        }
      }
      return realm;
  });
  

  useEffect(() => {
    if (realmsPackage) {
      const storedrealm = localStorage.getItem('realmID');
      
      // Check if the stored realm exists in the package
      if (!realmsPackage.find(pkg => pkg.name === storedrealm)) {
        // If not, set the first realm in the package
        if(realmsPackage.length > 0)
          setRealmID(realmsPackage[0].name);
      } else {
        // If it exists, set the stored realm ID
        setRealmID(storedrealm);
      }
    }
  }, [realmsPackage]);

  // Persist realmID to localStorage whenever it changes
  useEffect(() => {
    if (realmID) {
      localStorage.setItem('realmID', realmID);
    } else {
      localStorage.removeItem('realmID');
    }
  }, [realmID]);

  return (
    <RealmContext.Provider value={{ realmID, setRealmID }}>
      {children}
    </RealmContext.Provider>
  );
};

export default RealmProvider;
