import React from 'react';
import { Row, Col, Layout, Tabs, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css'; // Import Ant Design styles
import RealmObject from './RealmObject';
import RealmObjectsDisplay from './RealmObjectsDisplay';
import CampaignManager from './CampaignManager'; // Import the Campaign panel component
import CampaignDetails from './CampaignDetails'; // Import the Campaign details component
import { useRealmContext } from '../contexts/RealmProvider'; // Import the Realm context

import './css/CenterPanel.css';

const { Content } = Layout;
const { TabPane } = Tabs; // Destructure Tabs.TabPane
const { Title } = Typography;

const CenterPanel = () => {
  const { realmID } = useRealmContext(); // Get realmID from the context
  return (
    <Content className="panel-background-medium"> {/* Add center-panel class here */}
      <Tabs id='CenterPanelTabs' defaultActiveKey="1" className='center-panel'>
        {/* Tab for Realms */}
        <TabPane tab={<Title level={2} className='custom-title'>{realmID}</Title>} key="1" className='center-panel'>
          <Row id="CenterPanel" className="element-margined-small">
            <Col xs={24} sm={24} md={12} lg={9} xl={7} className="">
              <RealmObjectsDisplay />
            </Col>
            <Col id="Realm Object Col" xs={24} sm={24} md={12} lg={15} xl={17} className="">
              <RealmObject />
            </Col>
          </Row>
        </TabPane>

        {/* Tab for Campaigns */}
        <TabPane tab={<Title level={3} className='custom-title'><RightOutlined /> Campaigns</Title>} key="2">
          <Row id="CampaignPanel" className="element-margined-small">
            {/* Here you can customize the layout for Campaigns */}
            <Col xs={24} sm={24} md={12} lg={9} xl={7} className="">
              {/* Campaign list or display goes here */}
              <CampaignManager />
            </Col>
            <Col id="Campaign Object Col" xs={24} sm={24} md={12} lg={15} xl={17} className="">
              <CampaignDetails/>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      
    </Content>
  );
};

export default CenterPanel;
