import { useCallback } from 'react';
import RealmObjectService from '../services/RealmObjectService'; // Adjust the import path
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

export const useAttributeManagement = () => {
   const {realmObjectList, realmObject, setRealmObject, setRealmObjectList, setRealmObjectEdited} = useRealmObjectContext();
   //const {realmID} = useRealmContext();

  // Add a context attribute
  const addContextAttribute = useCallback((attributeName, attributeValue, attributeNotes, targetObject) => {
    if (!targetObject || !targetObject.payload) {
      console.error('Invalid target object:', targetObject);
      targetObject = realmObject;
    }
    const updatedRealmObject = { ...targetObject, payload: RealmObjectService.addContextAttribute(targetObject.payload, attributeName, attributeValue, attributeNotes)};
    setRealmObject(updatedRealmObject);
    //item in the list with the same realmObjectID as updatedRealmObject which is an array of realmObjects
    setRealmObjectList(
      realmObjectList.map(item => 
        item.RealmObjectID === updatedRealmObject.RealmObjectID ? updatedRealmObject : item
      )
    );    
    setRealmObjectEdited(true);
  }, []);

  // Remove a context attribute by name
  const removeContextAttribute = useCallback((attributeName, targetObject = realmObject) => {
    const updatedRealmObject = { ...targetObject, payload: RealmObjectService.removeContextAttribute(targetObject.payload, attributeName)};
    setRealmObject(updatedRealmObject);
    setRealmObjectList(
      realmObjectList.map(item => 
        item.RealmObjectID === updatedRealmObject.RealmObjectID ? updatedRealmObject : item
      )
    );   
  }, []);

  // Get context attributes from the payload
  const getContextAttributes = useCallback((targetObject = realmObject) => {
    return RealmObjectService.getContextAttributes(targetObject.payload);
  }, []);

  return {
    
    addContextAttribute,
    removeContextAttribute,
    getContextAttributes
  };
};
