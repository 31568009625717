import ApiCaller from "./ApiCaller";
import PortalConfiguration from "../configuration/config";
import { message } from 'antd';


class PackagesService {
    static async createPackage(apiCaller,sessionID, PackageData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/Packages',
                method: 'PUT',
                body: PackageData,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error creating Package:', error);
            throw new Error(error.response?.data || { error: 'Failed to create Package' });
        }
    }

    static async deletePackage(apiCaller,sessionID, userId, packageId) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/Packages',
                method: 'DELETE',
                params: {
                    userId,
                    packageId
                },
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error deleting Package:', error);
            throw new Error(error.response?.data || { error: 'Failed to delete Package' });
        }
    }

    static async updatePackage(apiCaller,sessionID, PackageData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/Packages',
                method: 'PATCH',
                body: PackageData,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error updating Package:', error);
            throw new Error(error.response?.data || { error: 'Failed to update Package' });
        }
    }

    static async getPackages(apiCaller,sessionID, queryParams) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/Packages',
                method: 'GET',
                params: queryParams,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error fetching Packages:', error);
            throw new Error(error.response?.data || { error: 'Failed to fetch Packages' });
        }
    }

    static async getCollections(apiCaller,sessionID) {
        try {
            console.log("getCollections");
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/collections',
                method: 'GET',
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID // Assuming this is your application ID
                  }
            });
            return result.collections;
        } catch (error) {
            console.error('Error fetching collections:', error);
            throw new Error(error.response?.data || { error: 'Failed to fetch collections' });
        }
    }
}

export default PackagesService;
