import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, Typography } from 'antd';
import './css/attributeModal.css';

const { Title } = Typography;
const { TextArea } = Input;

const AddAttributeModal = ({ isVisible, onAdd, onCancel, newName }) => {
  const [newAttribute, setNewAttribute] = useState({ name: newName, value: '', notes: '' });

  useEffect(() => {
    // Reset the form fields whenever the modal becomes visible or the name changes
    if (isVisible) {
      setNewAttribute({ name: newName, value: '', notes: '' });
    }
  }, [isVisible, newName]);

  const handleOk = () => {
    const { name, value, notes } = newAttribute;
    if (name && value) {
      onAdd(newAttribute);
      setNewAttribute({ name: '', value: '', notes: '' }); // Reset the form
    }
  };

  return (
    <Modal
      title={<Title level={3} className='custom-title'>Add New Attribute</Title>}
      open={isVisible}
      onOk={handleOk}
      onCancel={onCancel}
      footer={null}
      className="attribute-modal panel-background-medium panel-border-light"
    >
      <Form layout="vertical" className="login-form panel-background-medium panel-border-light">
        {/* Hidden Input for Attribute Name */}
        <Form.Item style={{ display: 'none' }}>
          <Input 
            value={newAttribute.name}
            onChange={e => setNewAttribute({ ...newAttribute, name: e.target.value })}
            
          />
        </Form.Item>

        <Form.Item label="Attribute Value" className='custom-form-item'>
          <Input 
            placeholder="Enter attribute value" 
            value={newAttribute.value} 
            onChange={e => setNewAttribute({ ...newAttribute, value: e.target.value })} 
            className="large-size"
          />
        </Form.Item>
        <Form.Item label="Instructions" className='custom-form-item'>
          <TextArea 
            placeholder="Enter Instructions on how to use this attribute in the context of your world." 
            value={newAttribute.notes} 
            onChange={e => setNewAttribute({ ...newAttribute, notes: e.target.value })} 
            className="large-size"
            autoSize={{ minRows: 3, maxRows: 6 }} // Adjust minRows and maxRows as needed
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleOk} block className="primary large-size">
            Add Attribute
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAttributeModal;
