import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { CampaignRecordService } from '../services/CampaignRecordService';
import { useRealmContext } from './RealmProvider'; // Import the realm context

// Create the context
const CampaignContext = createContext();

export const useCampaignContext = () => {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error('useCampaignContext must be used within a CampaignProvider');
    }
    return context;
}

export const CampaignProvider = ({ children }) => {
  const {
    getRealmCampaigns,
    getCampaign,
    getCampaignUsers,
    getCampaignNotes,
    getCampaignSessions,
    getCampaignOverrideRealmObjects,
  } = CampaignRecordService();

  const { realmID } = useRealmContext(); // Get realmID from the RealmProvider

  const [campaignList, setCampaignList] = useState([]);
  const [campaign, setCampaign] = useState(null);
  const [campaignUsers, setCampaignUsers] = useState([]);
  const [campaignNotes, setCampaignNotes] = useState([]);
  const [campaignSessions, setCampaignSessions] = useState([]);
  const [campaignOverrideRealmObjects, setCampaignOverrideRealmObjects] = useState([]);

  

  const updateCampaignList = useCallback(() => {
    // Fetch campaigns for the current realm
    getRealmCampaigns(realmID).then(campaigns => {
      setCampaignList(campaigns);
    }).catch(err => {
      console.error('Failed to fetch campaigns:', err);
      setCampaignList([]);
    });
  }, [realmID, getRealmCampaigns]);

  const updateCampaignUsers = useCallback(() => {
    if (campaign) {
      // Fetch all related records for the selected campaign
      const fetchRecords = async () => {
        try {
          const users = await getCampaignUsers(campaign.PK_CampaignID);
          setCampaignUsers(users);
        } catch (error) {
          console.error('Failed to fetch campaign users:', error);
          setCampaignUsers([]);
        }
      };

      fetchRecords();
    } else {
      setCampaignUsers([]);
    }
  }, [campaign, getCampaignUsers]);

  const updateCampaignNotes = useCallback(() => {  
    if (campaign) {
      // Fetch all related records for the selected campaign
      const fetchRecords = async () => {
        try {
          const notes = await getCampaignNotes(campaign.PK_CampaignID);
          setCampaignNotes(notes);
        } catch (error) {
          console.error('Failed to fetch campaign notes:', error);
          setCampaignNotes([]);
        }
      };

      fetchRecords();
    } else {
      setCampaignNotes([]);
    }
  }, [campaign, getCampaignNotes]);

  // Load campaigns whenever realmID changes
  useEffect(() => {
    if (realmID) {
      // Fetch campaigns for the current realm
      getRealmCampaigns(realmID).then(campaigns => {
        setCampaignList(campaigns);
      }).catch(err => {
        console.error('Failed to fetch campaigns:', err);
        setCampaignList([]);
      });
    } else {
      setCampaignList([]);
    }
  }, [realmID]);

  // Load campaign records whenever a campaign is set
  useEffect(() => {
    if (campaign) {
      // Fetch all related records for the selected campaign
      const fetchRecords = async () => {
        try {
          const [users, notes, sessions, overrideRealmObjects] = await Promise.all([
            getCampaignUsers(campaign.PK_CampaignID),
            getCampaignNotes(campaign.PK_CampaignID),
            getCampaignSessions(campaign.PK_CampaignID),
            getCampaignOverrideRealmObjects(campaign.PK_CampaignID),
          ]);

          setCampaignUsers(users);
          setCampaignNotes(notes);
          setCampaignSessions(sessions);
          setCampaignOverrideRealmObjects(overrideRealmObjects);
        } catch (error) {
          console.error('Failed to fetch campaign records:', error);
          setCampaignUsers([]);
          setCampaignNotes([]);
          setCampaignSessions([]);
          setCampaignOverrideRealmObjects([]);
        }
      };

      fetchRecords();
    } else {
      // Reset if no campaign is selected
      setCampaignUsers([]);
      setCampaignNotes([]);
      setCampaignSessions([]);
      setCampaignOverrideRealmObjects([]);
    }
  }, [campaign]);

  // Function to set the active campaign
  const selectCampaign = (campaignId) => {
    const selectedCampaign = campaignList.find(c => c.PK_CampaignID === campaignId);
    if (selectedCampaign) {
      setCampaign(selectedCampaign);
    } else {
      setCampaign(null);
    }
  };

  // Provide context value
  const contextValue = {
    campaignList,
    updateCampaignList,
    campaign,
    selectCampaign,
    campaignUsers,
    updateCampaignUsers,
    campaignNotes,
    updateCampaignNotes,
    campaignSessions,
    campaignOverrideRealmObjects,
  };

  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

// Custom hook for accessing the campaign context

export default CampaignProvider;
