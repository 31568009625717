import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Typography, Input, Space } from 'antd'; // Import Space component
import { InfoCircleOutlined } from '@ant-design/icons';
import './css/QuestionCard.css';
import '..'

const { Text,Title } = Typography;
const { TextArea } = Input;

const QuestionCard = ({ item, inputValue, handleChange, focusedInput, setFocusedInput }) => {
  const [tempValue, setTempValue] = useState(inputValue);

  // Synchronize tempValue with inputValue when inputValue changes
  useEffect(() => {
    setTempValue(inputValue);
  }, [inputValue]);

  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleBlur = () => {
    if (tempValue !== inputValue) {
      handleChange(tempValue, item.Label);
    }
    setFocusedInput(null);
  };

  return (
    <Card className="question-card-item question-card-label" 
        bordered={true} title={<Space><Title className="question-card-header" 
        level={5}>{item.Label}</Title><Tooltip title={item.Description || "No description"}>
        <InfoCircleOutlined className="question-card-icon" />
      </Tooltip></Space>}>

      <TextArea
        id={`${item.Label.trim()}-input`}
        className="custom-input"
        placeholder={item.defaultValue || "Let Fabricator decide! or Enter answers!"}
        value={tempValue}
        onChange={handleInputChange}
        onFocus={() => setFocusedInput(item.Label)}
        onBlur={handleBlur}
        autoSize={{ minRows: 3, maxRows: 5 }} // Multiple line entry field
      />
    </Card>
  );
};

// Custom comparison function for memoization
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.inputValue === nextProps.inputValue &&
    prevProps.focusedInput === nextProps.focusedInput &&
    prevProps.item.Label === nextProps.item.Label &&
    prevProps.item.Description === nextProps.item.Description
  );
};

// Exporting memoized component
export default React.memo(QuestionCard, areEqual);
