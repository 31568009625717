import React, { useEffect, useState, useRef } from 'react';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { useRealmObjectFetching } from '../hooks/useRealmObjectFetching';
import { useRealmContext } from '../contexts/RealmProvider';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config';
import { useAuthContext } from '../contexts/AuthProvider';
import useAccessControl from '../hooks/useAccessControl';
import { useRealmObjectCrud } from '../hooks/useRealmObjectCrud';
import { Portal } from '@mui/material';

export const RealmObjectManager = () => {
  
  const { realmID } = useRealmContext();
  const { sessionID, username } = useAuthContext(); // Get auth info from AuthContext
  const apiCaller = new ApiCaller(PortalConfiguration.realmobjectsURL);
  
  const {
       realmObjectID, 
       realmObjectList, 
       realmObject,
       setRealmObject, 
       setRealmObjectList, 
       setRealmObjectEdited,
       setIncomingRealmObjectLinks,
       setOutgoingRealmObjectLinks,
       setLoading,
       setAllowedAccessors,
       realmObjectEdited,
    } = useRealmObjectContext();
    
    const { 
      fetchRealmObject,
      fetchRealmObjectList,
      getIncomingRealmObjectLinks,
      getOutgoingRealmObjectLinks 
    } = useRealmObjectFetching(apiCaller, sessionID, username, ApiCaller.handleApiError);
    const {getAccessorList} = useAccessControl();

        
    // Load the realm object whenever realmObjectID changes
    useEffect(() => {
        console.log('realmObjectID changed. Fetching realm object',realmObjectID);

        // Handle null or undefined realmID case
        if (!realmID) {
            // Optionally clear the realm object and links if no realmID is available
            setRealmObject(null);
            setRealmObjectEdited(false);
            setIncomingRealmObjectLinks([]);
            setOutgoingRealmObjectLinks([]);
            setAllowedAccessors([]);
            return;
        }

        if (!realmObjectID) {
            return; // Exit early if there's no realmObjectID
        }

        // Async function to fetch the realm object and related data
        async function fetchData() {
            
            setLoading('isLoadingObject', true);
            setLoading('isLoadingItem', true);
            setLoading('isFetchingLinks', true);

            try {
                const inMemRealmObject = realmObjectList.find((item) => item.RealmObjectID === realmObjectID);
                await setRealmObject(inMemRealmObject);
                await setAllowedAccessors([]);
                await Promise.all([
                    fetchRealmObject(realmID),                    
                    getIncomingRealmObjectLinks(realmID,realmObjectID),
                    getOutgoingRealmObjectLinks(realmID,realmObjectID),
                    getAccessorList(realmObjectID)
                ]);

            } catch (error) {
                console.error("Error fetching realm object data: ", error);
            } finally {
                setLoading('isFetchingLinks', false);
                setLoading('isLoadingItem', false);
                setLoading('isLoadingObject', false);
            }
        }

        fetchData();
    }, [realmObjectID]);

    // Fetch realm object list whenever realmID changes
    useEffect(() => {
        console.log('realmID changed. Fetching realm object list');
        
        // Handle the case where realmID is null
        if (!realmID) {
            setRealmObjectList([]);  // Clear the object list if realmID is not available
            return;
        }

        // Fetch realm object list when realmID is available
        fetchRealmObjectList(realmID);
    }, [realmID]);



    return <div></div>; // No UI component, but you could return loading or fallback elements here if necessary
};
