import { useMemo } from 'react';
import { Space, Button, Typography } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import IconifiedText from '../components/IconifiedText';
import { useRealmContext } from '../contexts/RealmProvider';
import { useCallback, useState } from 'react';

const useTemplateTree = (templates, searchTerm, handleCreateClick) => {

  const { realmID } = useRealmContext();
  const { Title } = Typography;
  const [expandedKeys, setExpandedKeys] = useState([]);

  const toggleExpandedKey = useCallback(key => {
    setExpandedKeys(prevKeys => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter(k => k !== key);
      }
      return [...prevKeys, key];
    });
  }, []);

  const createTreeNode = useCallback((category = false, template) => {
    if (category) { 
      return (
        <Title level={4} 
        className='custom-title' 
        onClick={() => toggleExpandedKey(template.key)}>
          {template.title}
        </Title>
      );
    }
    return (
        <Space justify='space-between' align='center' className="fab-fill-parent"
          template={template}>
          <div
            className={`realm-object-title`} >
            {/* IconifiedText on the left */}
            <IconifiedText
              text={template.name || 'Unnamed'}
              iconFileIndex={template.payload?.iconFileIndex || 0}
              iconIndex={template.payload?.iconIndex || 0}
              iconDisplaySize={30}
              textType="large"
              textStyle="tree"
            />
          </div>
            {template && (
              <Button 
                className="ant-btn-rounded primary-small"
                onClick={() => handleCreateClick(template)}
                disabled={!realmID}
                icon={<SendOutlined />}
              />
            )}
        </Space>
        );
  }, [handleCreateClick, realmID]);
  
  // Function to transform templates into tree data format
  const treeData = useMemo(() => {
    if (!templates || templates.length === 0) return [];

    // Group templates by category
    const categoryMap = {};
    templates.forEach(template => {
      const category = template.payload.category || 'Uncategorized';
      if (!categoryMap[category]) {
        categoryMap[category] = [];
      }
      categoryMap[category].push(template);
    });

    // Sort the categories alphabetically
    const sortedCategoryMap = Object.keys(categoryMap)
      .sort() // Sort the category names alphabetically
      .reduce((sortedMap, category) => {
        sortedMap[category] = categoryMap[category];
        return sortedMap;
      }, {});

    // Convert category map to tree structure
    const treeNodes = Object.keys(sortedCategoryMap).map(category => ({
      title: createTreeNode(true, { title: category, key: category }),
      key: category,
      children: sortedCategoryMap[category].map(template => ({
        title: createTreeNode(false, template),
        key: template.templateID,
        template,  // Keep a reference to the template object
      })),
    }));

    // If there's a search term, filter templates based on the name
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      return treeNodes.map(node => ({
        ...node,
        children: node.children.filter(item => {
          const templateName = item.template?.name || ''; // Ensure templateName is a string
          return templateName.toLowerCase().includes(searchLower); // Filter by name, not title
        }),
      })).filter(node => node.children.length > 0); // Remove empty categories
    }

    return treeNodes;
  }, [templates, searchTerm, createTreeNode]);

  return { treeData, expandedKeys, setExpandedKeys };
};

export default useTemplateTree;
