import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Spin, Input, Typography, InputNumber, List, Space } from 'antd';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { SaveFilled, EditOutlined } from '@ant-design/icons'; // Import necessary icons
import { useAuthContext } from '../contexts/AuthProvider';
import { useAppContext } from '../contexts/AppProvider';
import ToggleOneToZero from './SmallComponents/ToggleOneToZero'; // Import the custom component
import './css/FoundryTab.css'; // Import your custom CSS
import './css/CustomSpinner.css'; // Import your custom CSS
import useFabrication from '../hooks/useFabricate';
import { useRealmContext } from '../contexts/RealmProvider';

const { Title, Text } = Typography;
const { TextArea } = Input;

const FoundryTab = () => {
  const { realmID} = useRealmContext();
  const { sessionID, username } = useAuthContext();
  const { 
    realmObject, 
    isFabricating, 
    setRealmObjectEdited,
    updateRealmObject,
    objectsWithinDegreesOfSeparation
  } = useRealmObjectContext();
  
  const { userPermissionLevel } = useAuthContext();
  const { featureFlags } = useAppContext();
  const {fabricateRealmObject} = useFabrication(sessionID, realmID, username);
  
  const [isEditing, setIsEditing] = useState(false);
  const [tempTemplateDescription, setTempTemplateDescription] = useState(realmObject?.payload?.templateDescription || '');
  const [degreeOfSeparation, setDegreeOfSeparation] = useState(0);
  const [relatedObjects, setRelatedObjects] = useState([]);

  useEffect(() => {
    setTempTemplateDescription(realmObject?.payload?.templateDescription || '');
  }, [realmObject]);

  const handleTemplateChange = (e) => {
    const newTemplateDescription = e.target.value;
    setTempTemplateDescription(newTemplateDescription);
    
  };

  const toggleEditMode = () => {
    if (isEditing) {
      updateRealmObject('templateDescription', tempTemplateDescription);
      setRealmObjectEdited(true);
    }
    setIsEditing(!isEditing);
  };

  const handleFabricate = () => {
    if(degreeOfSeparation === 0) {
      fabricateRealmObject([])
        .catch(error => console.error('Error during fabrication:', error));
    }else {
      fabricateRealmObject(relatedObjects)
        .then(() => console.log('Fabrication complete'))
        .catch(error => console.error('Error during fabrication:', error));
    }
  };

  useEffect(() => {
    const objects = objectsWithinDegreesOfSeparation(degreeOfSeparation);
    setRelatedObjects(objects || []);
  }, [degreeOfSeparation, objectsWithinDegreesOfSeparation]);

  const questionsFilled = realmObject?.payload?.Questions?.filter(
    question => question.CurrentValue && question.CurrentValue?.trim() !== ''
  ).length || 0;

  const totalQuestions = realmObject?.payload?.Questions?.length || 0;

  return (
    <Row gutter={[16, 16]} className='element-margined-tiny' justify="start">
      {/* Dashboard Section */}
      <Col span={24}>
        <Title level={3} className="custom-title">Operations Center</Title>

        <Row justify="space-between" align="middle">
          <Title level={4} className="ant-typography custom-title">Template Description: </Title>
          <Button 
            icon={isEditing ? <SaveFilled /> : <EditOutlined />} 
            className="primary"
            onClick={toggleEditMode}
          />
        </Row>

        {isEditing ? (
          <TextArea
            value={tempTemplateDescription}
            onChange={handleTemplateChange}
            placeholder="Enter template description"
            className="custom-input"
            autoSize={{ minRows: 3, maxRows: 5 }} 
          />
        ) : (
          <Text className="ant-typography text-secondary">
            {realmObject?.payload?.templateDescription || 'custom'}
          </Text>
        )}
      </Col>

      {/* Context Section */}
      <Col span={24}>
          <Title level={4} className="ant-typography custom-title">Context: </Title>
          <Space>
          <Text className="ant-typography text-secondary">
            {questionsFilled} of {totalQuestions} questions filled out.
          </Text>

          <Button 
            className="ant-btn primary element-margined-small-left" 
            onClick={handleFabricate} 
            disabled={isFabricating}
          >
            Fabricate
          </Button>
        </Space>
        {isFabricating && <Spin className='custom-spin' style={{ marginLeft: 10 }} />}
      </Col>
      {/* Degrees of Separation Section */}
      {featureFlags.getUserFlag(userPermissionLevel, 'DEGREES_OF_SEPERATION') > 0 && (
        <>
          <Col span={24}>
            <Space>
              <Title level={4} className="custom-title">Degrees of Separation</Title>
              <ToggleOneToZero initialValue={degreeOfSeparation} onToggleChange={setDegreeOfSeparation} labelOn = "1" labelOff = "0" />
            </Space>
          </Col>

          {/* Related Objects Section */}
          {degreeOfSeparation > 0 && (
            <Col span={24}>
              <Title level={5} className="custom-title">Related Objects</Title>
              <List
                className="linked-objects-list"
                bordered
                dataSource={relatedObjects}
                renderItem={item => (
                  <List.Item className="linked-objects-list-item">
                    <Text className='text-primary'>{item.name || `Object ID: ${item.RealmObjectID}`}</Text>
                  </List.Item>
                )}
              />
            </Col>
          )}
        </>
      )}
    </Row>
  );
};

export default FoundryTab;
