import { useCallback } from 'react';
import RealmObjectService from '../services/RealmObjectService'; // Adjust the import path
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { useRealmObjectCrud } from './useRealmObjectCrud';
import { useRealmObjectFetching } from './useRealmObjectFetching';
import ApiCaller from '../services/ApiCaller';


export const useRealmOrder = (apiCaller, sessionID, username, realmID) => {
  const {realmObjectList, realmObject, setRealmObject, setRealmObjectList, setRealmObjectEdited, realmObjectID} = useRealmObjectContext();
  const { saveRealmObject } = useRealmObjectCrud(apiCaller, sessionID, username, realmID);
  const { fetchRealmObjectList } = useRealmObjectFetching(apiCaller, sessionID, username, ApiCaller.handleApiError);
  //const {realmID} = useRealmContext();
  // Ensure all realm objects have unique realmOrder and update them in the database
  const ensureUniqueRealmOrder = useCallback(async (items) => {
  
    let updatingRealmObjectList = (!items || items.length === 0);
    if (updatingRealmObjectList) {
      items = realmObjectList;
    }
    try {
      const updatedItems = await RealmObjectService.ensureUniqueRealmOrder(
        items, 
        apiCaller, 
        sessionID, 
        username, 
        realmID
      );

      if (!updatingRealmObjectList) {
        return updatedItems;
      } else {
        setRealmObjectList(updatedItems);
      }
    } catch (error) {
      console.error('Error ensuring unique realm order:', error);
      throw error;
    }
  }, [realmObjectList, apiCaller, sessionID, username, realmID, setRealmObjectList]);

  // Set realm order in the payload
  const setRealmOrder = useCallback(async (realmOrder, targetObject = realmObject) => {
    const payload = RealmObjectService.setRealmOrder(targetObject.payload, realmOrder);
    const updatedObject = { ...targetObject, payload };
    if (realmObjectID === updatedObject.RealmObjectID) {
       setRealmObject(updatedObject);
       await saveRealmObject(updatedObject);
       setRealmObjectEdited(false);
    }
    else {
       await saveRealmObject(updatedObject);
    }
    setRealmObjectList(
      realmObjectList.map(item => 
        item.RealmObjectID === updatedObject.RealmObjectID ? updatedObject : item
      )
    );
    fetchRealmObjectList(realmID);
  }, [realmObject, realmObjectList, setRealmObject, setRealmObjectList]);

  const moveInRealmOrder = useCallback(async (objectMoving, anchorObject) => {
    let newOrder;
    let siblings;
    let sortedSiblings;
    const objectList = realmObjectList
    try {
      // Find siblings with the same parentID
      siblings = objectList.filter(obj => obj.parentID === anchorObject.parentID);
      if (siblings.length > 0) {
        sortedSiblings = siblings.sort((a, b) => (a.payload.realmOrder || 0) - (b.payload.realmOrder || 0));
      } 
      
      if(anchorObject.RealmObjectID === objectMoving.parentID) {
        //moving to top of list
        // Find the first sibling in the list. 
        // If there are no siblings, set the new order to 10.
        // Otherwise, set the new order to half the order of the first sibling.
        if (sortedSiblings.length > 0) {
          newOrder = sortedSiblings[0].payload.realmOrder / 2;
        } else {
          newOrder = 10;
        }
        // Update the realmOrder for the object being moved       

      }
      else {
        //moving normally.
        const anchorObjectOrder = anchorObject.payload.realmOrder;
        // Find the next object with a higher order
        const nextObject = sortedSiblings.find(obj => obj.payload.realmOrder > anchorObjectOrder);
        // Calculate the new order between the anchorObject and the nextObject (or assign a default order if nextObject is not found)
        newOrder = nextObject ? (anchorObjectOrder + ((nextObject.payload.realmOrder - anchorObjectOrder) / 2)) : Number(anchorObjectOrder) + 1;
        // Update the realmOrder for the object being moved
        objectMoving.payload.realmOrder = newOrder;
        // Update realmObjectList with the saved objectMoving
      }
       // Save the updated object to the backend
       const newRealmObjectList = objectList.map(item => item.RealmObjectID === objectMoving.RealmObjectID ? objectMoving : item);
       setRealmObjectList(newRealmObjectList);
       //await RealmObjectService.saveRealmObject(apiCaller, sessionID, username, realmID, objectMoving, objectMoving.RealmObjectID);
       await saveRealmObject(objectMoving);
       if (realmObjectID === objectMoving.RealmObjectID)
       { 
         setRealmObject(objectMoving);
         setRealmObjectEdited(false);  
        }

    } catch (error) {
      console.error("Error during moveInRealmOrder:", error);
    }
  }, [realmObjectID, realmObjectList, saveRealmObject, setRealmObject, setRealmObjectEdited, setRealmObjectList]);
  
  const getHighestSiblingOrder = useCallback((parentID) => {
    const siblings = realmObjectList.filter(obj => obj.parentID === parentID);
    if (siblings.length === 0) {
      return 0;
    }
    const sortedSiblings = siblings.sort((a, b) => (a.payload.realmOrder || 0) - (b.payload.realmOrder || 0));
    //return the realmOrder of the last sibling + 10
    return sortedSiblings[sortedSiblings.length - 1].payload.realmOrder;
    
  }, [realmObjectList]);

  return {
    ensureUniqueRealmOrder,
    setRealmOrder,
    moveInRealmOrder,
    getHighestSiblingOrder
  };
};
