import React from 'react';
import { Button, Form, Input, Typography, Card, Tag } from 'antd';
import { DeleteFilled, UserAddOutlined, WarningOutlined } from '@ant-design/icons';
import './css/CampaignPlayerList.css'; 

const { Title, Text } = Typography;

const CampaignPlayerList = ({
  campaignUsers,
  showDeleteUserConfirm,
  handleAddUser,
  userForm,
  errorMessage,
}) => {
  return (
    <Card className="fab-panel-card top-card" title={<Title level={4} className="custom-title center-title">Players</Title>}>
      {/* List of Users as Tags */}
      <div className="player-tag-list">
        {campaignUsers.length > 0 ? (
          campaignUsers.map((user) => (
            <Tag
              key={user.PK_UserID}
              closable
              onClose={(e) => {
                e.preventDefault(); // Prevent default tag behavior (prevent close from triggering automatically)
                showDeleteUserConfirm(user);
              }}
              closeIcon={<DeleteFilled />}
              className="player-tag"
            >
              {user.payload?.name ?? 'Unnamed Player'}
            </Tag>
          ))
        ) : (
          <Text className="text-hightlight">No players added yet.</Text>
        )}
      </div>

      {/* Form to add a user */}
      <Form layout="inline" onFinish={handleAddUser} form={userForm} className='element-margined-top-small'>
        <Form.Item name="userId" rules={[{ required: true, message: 'Enter a user ID' }]}>
          <Input placeholder="User ID" className='campaign-add-input'/>
        </Form.Item>
        <Form.Item>
          <Button icon={<UserAddOutlined />} type="primary" htmlType="submit" />
        </Form.Item>
        {errorMessage && (
          <Text type="danger">
            <WarningOutlined /> {errorMessage}
          </Text>
        )}
      </Form>
    </Card>
  );
};

export default CampaignPlayerList;
