import React, { createContext, useState, useContext, useEffect } from 'react';
import PortalConfiguration from '../configuration/config';
import FeatureFlags from '../configuration/featureFlags';


// Create the context
export const AppContext = createContext();

// Create a custom hook to use the AppContext
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

// Create the provider component
export const AppProvider = ({ children }) => {
  const [semifor, setSemifor] = useState(false);
  const [featureFlags] = useState(new FeatureFlags());
    
  return (
    <AppContext.Provider value={{ semifor, setSemifor, featureFlags}}>
      {children}
    </AppContext.Provider>
  );
};
