// helpers.js

/**
 * Sorts an array of items by a specified property.
 * @param {Array} items - Array of items to be sorted.
 * @param {string} property - The property name to sort by.
 * @param {string} order - 'asc' for ascending or 'desc' for descending.
 * @returns {Array} - Sorted array of items.
 */
export const sortItemsByProperty = (items, property, order = 'asc') => {
    return items.sort((a, b) => {
      const propA = a[property]?.toLowerCase() || ''; // Handle undefined or null values
      const propB = b[property]?.toLowerCase() || '';
  
      if (order === 'asc') {
        if (propA < propB) return -1;
        if (propA > propB) return 1;
        return 0;
      } else {
        if (propA > propB) return -1;
        if (propA < propB) return 1;
        return 0;
      }
    });
  };