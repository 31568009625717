import React, { useState, useEffect } from 'react';
import { Spin, Upload, Button, message, Typography, Space } from 'antd';
import { UploadOutlined,LoadingOutlined } from '@ant-design/icons';
import ImageComponent from './ImageComponent';
import useImageService from '../hooks/useImageServices';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

const { Text } = Typography;

const ImageLoader = ({ handleImageItemCreation, handleImageItemDeletion }) => {
  const { realmObject, isLoadingObject, updateRealmObject } = useRealmObjectContext();
  const { error, fetchImageBlob, uploadImage, deleteImage } = useImageService(); // Add deleteImage hook
  const [imageBlob, setImageBlob] = useState(null);
  const [selfLoad, setSelfLoad] = useState({ serviceID: null, objectID: null });
  const [imageAvailable, setImageAvailable] = useState(false);

  const service = 'realm-objects';

  const loadImage = async (serviceID, objectID) => {
    if (serviceID && objectID) {
      try {
        const blob = await fetchImageBlob(serviceID, objectID);
        setImageBlob(blob || null);  // Clear if blob is null
      } catch (error) {
        console.error('Error fetching image:', error);
        setImageBlob(null);
      }
    } else {
      setImageBlob(null);  // Clear the image immediately if selfLoad is null
    }
  };

  useEffect(() => {
    const setLoading = async () => {
      if (realmObject && realmObject.payload && realmObject.payload.images && 
        realmObject.payload.images.length > 0)
      {
        setImageAvailable(true);
        setSelfLoad({ serviceID: realmObject?.RealmID, objectID: realmObject?.RealmObjectID });
        await loadImage(realmObject?.RealmID, realmObject?.RealmObjectID);
      }
      else {
        setImageAvailable(false);
        setSelfLoad({ serviceID: realmObject?.RealmID, objectID: realmObject?.RealmObjectID });
        setImageBlob(null);
      }   
    };    
    setLoading();
  }, [realmObject]);

  const handleImageUpload = async (options) => {
    const { file, onSuccess, onError, onProgress } = options;
    try {
      message.loading({ content: 'Uploading...', key: 'upload' });
      console.log('file', file);

      // Perform the upload using the upload service
      const uploadResult = await uploadImage(file, service, selfLoad?.serviceID, selfLoad?.objectID);
      console.log('uploadResult', uploadResult);
      if (handleImageItemCreation) handleImageItemCreation(uploadResult);

      onSuccess('Upload successful');
      message.success({ content: 'Upload complete!', key: 'upload', duration: 2 });

      // Reload the image after upload
      const blob = await fetchImageBlob(selfLoad?.serviceID, selfLoad?.objectID);
      setImageBlob(blob || null); // Set blob or null after upload
      if (blob === null) {
        setTimeout(async () => {
          const blob = await fetchImageBlob(selfLoad?.serviceID, selfLoad?.objectID);
          setImageBlob(blob || null); // Set blob or null after upload
        }, 3000);
      }
    } catch (err) {
      onError(err);
      message.error({ content: 'Upload failed!', key: 'upload', duration: 2 });
    }
    onProgress({ percent: 100 });
  };

  const handleOnChange = ({ file, fileList, event }) => {
    console.log('onchange', file, fileList, event);
  };

  const handleImageDelete = async () => {
    try {
      message.loading({ content: 'Deleting image...', key: 'delete' });
      await deleteImage(selfLoad.serviceID, selfLoad.objectID); // Assuming deleteImage is your delete service function
      setImageBlob(null); // Clear the image from state

      if (handleImageItemDeletion) handleImageItemDeletion(selfLoad);
    } catch (error) {
      message.error({ content: 'Error deleting image', key: 'delete', duration: 2 });
    }
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Space id='001113' className='fill-height fill-width fab-center-content '>
      <Spin id='001112' className='custom-spin' spinning={isLoadingObject && imageAvailable} />
        {(!isLoadingObject || !imageAvailable) && (
          <div id='00111'>
            {error && error.statusCode !== 404 ? (
              error.statusCode === 403 ? (
                <Text className="text-primary">Image is being checked for security, check back in a while.</Text>
              ) : (
                <Text type="danger">Error: {error.message || 'Unknown error occurred'}</Text>
              )
            ) : ( imageAvailable ? (
              <ImageComponent key={selfLoad?.objectID} imageKey={selfLoad?.objectID} blob={imageBlob} onDelete={handleImageDelete} />
            ) : (
                // Only show the upload button when not loading
                <div className='fab-panel-card fill-height'>
                  <Upload
                    accept="image/png, image/webp"
                    showUploadList={false}
                    onChange={handleOnChange}
                    customRequest={handleImageUpload}
                    className='fill-height'
                  >
                    <Button className='fill-height' icon={<UploadOutlined />}>Add Image</Button>
                  </Upload>
                </div>
            ))}
          </div> 
        )}
    </Space>
  ); 
};

export default ImageLoader;
