import { Typography, List, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import EmptyControlContent from './EmptyControlContent';
import './css/namelist.css';

const Namelist = ({ deleteHandler, allowedAccessors, emptyText= 'Share with someone' }) => {
    
    const { Text, Title } = Typography;

    return (
        <div>
            {allowedAccessors.length > 0 && (
                <List
                    className='fab-panel-main'
                    bordered
                    dataSource={allowedAccessors || []}
                    renderItem={item => (
                        <List.Item
                            actions={[
                                <Button
                                    className="secondary-btn center-element"
                                    icon={<DeleteOutlined />}
                                    onClick={() => deleteHandler(item)} // Call the delete handler
                                    type="text"
                                />
                            ]}
                        >
                            <Text className='name-list-text'>{item.accessId}</Text>
                        </List.Item>
                    )}
                    locale={{ emptyText: <EmptyControlContent emptyText={emptyText} /> }}
                />
            )}
        </div>
    );
}

export default Namelist;
