import { useState, useEffect, useCallback } from 'react';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import RealmObjectTreeTitle from '../components/RealmObjectTreeTitle';
import PortalConfiguration from '../configuration/config'; // Import PortalConfiguration module
import { useRealmObjectCrud } from '../hooks/useRealmObjectCrud'; // Import useRealmObjectCrud hook
import { useRealmOrder } from '../hooks/useRealmOrder'; // Import useRealmOrder hook
import { useAuthContext } from '../contexts/AuthProvider'; // Import useAuthContext from AuthProvider
import { useRealmContext } from '../contexts/RealmProvider'; // Import useRealmContext from RealmProvider
import { useRealmObjectFetching } from './useRealmObjectFetching';

const useRealmTreeHandler = () => {
  const {sessionID, username} = useAuthContext();
  const {realmID} = useRealmContext();
  const { 
    realmObjectList, 
    realmObjectID, 
    realmObject,
    setRealmObject,
    setRealmObjectDragTarget, 
    realmObjectAPICaller,
    setRealmObjectEdited,
    setRealmObjectList,
  } = useRealmObjectContext();
  
  const {saveRealmObject, deleteRealmObject, updateRealmObject} = useRealmObjectCrud(realmObjectAPICaller, sessionID, username, realmID);
  const { ensureUniqueRealmOrder, setRealmOrder, moveInRealmOrder, getHighestSiblingOrder} = useRealmOrder(realmObjectAPICaller, sessionID, username, realmID);
  const {fetchRealmObjectList} = useRealmObjectFetching(realmObjectAPICaller, sessionID, username, realmID);
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const refreshTreeData = useCallback(async () => {
    if (!realmObjectList || realmObjectList.length === 0) {
      return;
    }
  
    // Find orphaned objects and update their parentID
    const updatedRealmObjectList = await homeForOrphanedObjects(realmObjectList); 
    const orderedRealmObjects = await ensureUniqueRealmOrder(updatedRealmObjectList || []);
    
    // Build the tree using the updated realm object list
    const tree = buildTree(orderedRealmObjects, PortalConfiguration.NoParentID);
    
    setTreeData(tree);
  }, [ensureUniqueRealmOrder, realmObjectList]);
  
  // Function to promote an item to a higher level in the tree
  const PromoteToHigherLevel = useCallback(async (item) => {
    const parentObject = realmObjectList.find(obj => obj.RealmObjectID === item.parentID);
    // If the item already has no parent, it cannot be promoted
    if (!parentObject) {
      console.warn('Item is already at the top level or parent not found');
      return;
    }

    // Set the parentID of the item to the parentID of the parent
    const newParentID = parentObject.parentID;
    const newSiblings = realmObjectList.filter(obj => obj.parentID === newParentID);
    let newRealmOrder = null;
    if (newSiblings.length > 0) {
      newRealmOrder = newSiblings[newSiblings.length - 1].payload.realmOrder + 10;
    } else {
      // Handle the case where newSiblings is empty
      newRealmOrder = 10; // or any default value you want to set
    }
    const updatedRealmObject = { ...item, parentID: newParentID, payload: { ...item.payload, realmOrder: newRealmOrder } };
    // Save the updated RealmObject and place it at the bottom of the new parent's children
    await setRealmOrder(newRealmOrder , updatedRealmObject);
    
    // Refresh the tree data after changes
    refreshTreeData();
  }, [realmObjectList, setRealmOrder, refreshTreeData]);

  // Function to make an item a child of the item just above it in the tree
  const MakeChildOfUpper = useCallback(async (item, siblings) => {
    // find the sibling closest to item in the list as determined by realmOrder
    const orderedSiblings = siblings.sort((a, b) => (a.payload.realmOrder || 0) - (b.payload.realmOrder || 0));
    const index = orderedSiblings.findIndex(obj => obj.RealmObjectID === item.RealmObjectID);
   
    // Ensure there is a sibling above the current item
    if (index <= 0) {
      console.warn('No sibling above, cannot make child of upper item');
      return;
    }
    const previousSibling = orderedSiblings[index - 1];
    // Prevent a circular reference
    if (previousSibling.RealmObjectID === item.RealmObjectID) {
      console.warn('Cannot make item a child of itself');
      return;
    }

    // Set the parentID of the item to the ID of the sibling just above
    const newParentID = previousSibling.RealmObjectID;
    const updatedRealmObject = { ...item, parentID: newParentID };
    const newSiblings = realmObjectList.filter(obj => obj.parentID === newParentID);
    let newRealmOrder = null;
    if (newSiblings.length > 0) {
      newRealmOrder = getHighestSiblingOrder(newSiblings) + 10;
    } else {
      // Handle the case where newSiblings is empty
      newRealmOrder = 10; // or any default value you want to set
    }
    // Save the updated RealmObject and place it at the bottom of the new parent's children
    await setRealmOrder(newRealmOrder, updatedRealmObject);

    // Refresh the tree data after changes
    refreshTreeData();
  }, [realmObjectList, setRealmOrder, refreshTreeData]);

  const handleDelete = useCallback(async (itemRealmObjectID) => {
    // Call the deleteRealmObject function from useRealmObjectCrud
    await deleteRealmObject(itemRealmObjectID);
    // Refresh the tree data after changes
    refreshTreeData();
  }, [deleteRealmObject, refreshTreeData]);

  const MakePublic = useCallback(async (item) => {
    if (realmObject.RealmObjectID === item.RealmObjectID) {
      const updatedRealmObject = { ...realmObject, publishedLevelACL: 'PUBLIC' };
      await saveRealmObject(updatedRealmObject);
      setRealmObject(updatedRealmObject);
      setRealmObjectList( realmObjectList.map(obj => obj.RealmObjectID === updatedRealmObject.RealmObjectID ? updatedRealmObject : obj));
      setRealmObjectEdited(false);
    } else {
      const updatedRealmObject = { ...item, publishedLevelACL: 'PUBLIC' };
      await saveRealmObject(updatedRealmObject);
    }
    // Refresh the tree data after changes
    refreshTreeData();
  }, []);

  const buildTree = useCallback((items, parentId = null, visited = new Set()) => {
    if (!Array.isArray(items)) {
      return []; 
    }
  
    // Filter items by parentId
    const filteredItems = items.filter((item) => item.parentID === parentId);
  
    // Sort the items based on payload.realmOrder before mapping them
    const sortedItems = filteredItems.sort((a, b) => (a.payload.realmOrder || 0) - (b.payload.realmOrder || 0));
  
    return sortedItems.map((item, index) => {
      if (visited.has(item.RealmObjectID)) {
        console.warn(`Circular reference detected: ${item.RealmObjectID}`);
        return null;
      }
  
      visited.add(item.RealmObjectID);
      const hasSiblingBefore = index > 0;
  
      return {
        title: (
          <RealmObjectTreeTitle 
            name={item.name} 
            payload={item.payload}
            onPromoteToHigherLevel={() => PromoteToHigherLevel(item)}
            onMakeChildOfUpper={() => MakeChildOfUpper(item, sortedItems)}
            onMakePublic={() => MakePublic(item)}
            onDelete={() => handleDelete(item.RealmObjectID)}
            isPublic={(item.publishedLevelACL === 'PUBLIC')}
            hasParent={item.parentID !== PortalConfiguration.NoParentID}
            hasSiblingBefore={hasSiblingBefore}
            onDrop={(info) => onDrop(info)}
          />
        ),
        key: item.RealmObjectID,
        children: buildTree(items, item.RealmObjectID, visited), 
      };
    }).filter(item => item !== null);
  }, [MakeChildOfUpper, PromoteToHigherLevel, deleteRealmObject]);
  
  // Function to handle orphaned objects
  const homeForOrphanedObjects = useCallback(async (items) => {
    const validParents = new Set(items.map(item => item.RealmObjectID));
    validParents.add(PortalConfiguration.NoParentID);
  
    // Iterate over all items and ensure parentID is valid
    const updatedItems = await Promise.all(items.map(async (item) => {
      if (!validParents.has(item.parentID)) {
        const updatedRealmObject = { ...item, parentID: PortalConfiguration.NoParentID };
        await saveRealmObject(updatedRealmObject); // Save the updated object
        return updatedRealmObject; // Return the updated object
      }
      return item; // Return the original item if no update is needed
    }));
  
    return updatedItems; // Return the list with updated objects
  }, [saveRealmObject]);
  

  // Effect to process realmObjectList and generate tree data
  useEffect(() => {
    refreshTreeData();
  }, [realmObjectList]);

  // Function to handle dropping an item in a new position within the tree
  const onDrop = async (info) => {
    try {
      console.log('drop info',info);

      const dropRealmObjectID = info.node.key;
      const dragRealmObjectID = info.dragNode.key;

      const dropRealmObject = realmObjectList.find(obj => obj.RealmObjectID === dropRealmObjectID);
      const dropRealmObjectParentID = dropRealmObject?.parentID;

      const newParentId = info.dropToGap ? dropRealmObjectParentID : dropRealmObjectID;
      const dragRealmObject = realmObjectList.find(obj => obj.RealmObjectID === dragRealmObjectID);
      
      let parentUpdated = (dragRealmObject.parentID !== newParentId);

      if (parentUpdated)
      {
        if (realmObjectID === dragRealmObjectID) {        
          const updatedRealmObject = { ...dragRealmObject, parentID: newParentId };
          await saveRealmObject(updatedRealmObject);
          setRealmObject(updatedRealmObject);
          fetchRealmObjectList(updatedRealmObject.RealmID);
        } else {
            const updatedRealmObject = { ...dragRealmObject, parentID: newParentId };
            await saveRealmObject(updatedRealmObject);
            fetchRealmObjectList(updatedRealmObject.RealmID);
        } 
      } else{
        await moveInRealmOrder(dragRealmObject, dropRealmObject);
      }

      
      // Refresh the tree after updating the parent-child relationship
      refreshTreeData();

    } catch (error) {
      console.error("Error during drop operation:", error.message);
    }
  };

  const onDragStart = (info) => {
    setRealmObjectDragTarget(info.node);
    console.log('Drag started:', info);
  };

  const onDragEnd = (info) => {
    setRealmObjectDragTarget(null);
    console.log('Drag ended',info);
  };
  
  return {
    treeData,
    expandedKeys,
    setExpandedKeys,
    onDrop,
    onDragStart,
    onDragEnd,
  };
};

export default useRealmTreeHandler;
