import { useState, useCallback, useMemo } from 'react';
import Fabrication from '../services/Fabrication'; // Adjust the path based on your file structure
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { useRealmObjectCrud } from './useRealmObjectCrud';
import { message } from 'antd';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config';
import { useLoadingState } from './useLoadingState';

// Hook to manage the fabrication process
const useFabrication = (sessionID, realmID, username) => {
  const apiCaller = useMemo(() => new ApiCaller(PortalConfiguration.fabricationURL), []);
  const realmObjectApiCaller = useMemo(() => new ApiCaller(PortalConfiguration.realmobjectsURL), []);

  const {realmObjectList, realmObject, setRealmObject, setRealmObjectList, setRealmObjectEdited, realmObjectID, setLoading, isFabricating} = useRealmObjectContext();
  const { saveRealmObject } = useRealmObjectCrud(realmObjectApiCaller, sessionID, username, realmID);
  //const {realmID} = useRealmContext();

    // State to track the fabrication process
  const [fabricationError, setFabricationError] = useState(null);  // State to track any errors
  
  // The fabricate function that calls the Fabrication service
  const fabricateRealmObject = useCallback(async (relatedObjects,targetObject = realmObject) => {
    setLoading('isFabricating',true); // Start fabricating
    setFabricationError(null); // Clear previous errors
    
    try {
      // Call the Fabrication service
      //apiCaller,sessionID, realmObject, relatedObjects, userID,realmID
      const response = await Fabrication.fabricate(apiCaller, sessionID, targetObject , relatedObjects, username, realmID);
      console.log('fabricateRealmObject response:', response);
      saveRealmObject(response); // Save the fabricated object to the RealmObjectList
      setRealmObjectList(
        realmObjectList.map(item => 
          item.RealmObjectID === response.RealmObjectID ? response : item
        )
      );   
      setLoading('isFabricating',false); // Fabrication finished successfully

      if (realmObjectID === response.RealmObjectID)
      { 
        setRealmObject(response);
        setRealmObjectEdited(false);
        message.success(response.name + ' fabricated successfully and saved automatically.');
      } 
      else 
      {
        message.success('Fabrication Complete background saving "'+ response.name+'"');
      }
      
       
    } catch (error) {
      setLoading('isFabricating',false);
      setFabricationError(error); // Set the error in state if something goes wrong
      console.error('Fabrication Error:', error);
      throw error; // Optionally rethrow the error to handle it elsewhere
    }
  }, [realmObject, setLoading, apiCaller, sessionID, username, realmID, saveRealmObject, setRealmObjectList, realmObjectList, realmObjectID, setRealmObject, setRealmObjectEdited]);

  const randomText = useCallback(async (textDescription) => {
      return Fabrication.randomText(apiCaller,sessionID, realmID, username, textDescription);
  }, [apiCaller, sessionID, realmID, username]);

  return {
    isFabricating,              // Boolean indicating if the process is still running
    fabricationError,           // Any error that occurred during the process
    fabricateRealmObject,
    randomText       // The function to trigger the fabrication process
  };
};

export default useFabrication;
