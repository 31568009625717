import PortalConfiguration from "../configuration/config";
import { Template } from '../types/templateTypes';

class TemplatesService {
    static async createTemplate(apiCaller, sessionID, templateData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/templates',
                method: 'PUT',
                body: templateData,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return new Template(result); // Convert the result to a Template instance
        } catch (error) {
            console.error('Error creating template:', error);
            throw new Error(error.response?.data || { error: 'Failed to create template' });
        }
    }

    static async deleteTemplate(apiCaller, sessionID, collectionID, templateID) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/',
                method: 'DELETE',
                params: {
                    collectionID,
                    templateID
                },
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error deleting template:', error);
            throw new Error(error.response?.data || { error: 'Failed to delete template' });
        }
    }

    static async updateTemplate(apiCaller, sessionID, templateData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/templates',
                method: 'PATCH',
                body: templateData,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return new Template(result); // Convert the result to a Template instance
        } catch (error) {
            console.error('Error updating template:', error);
            throw new Error(error.response?.data || { error: 'Failed to update template' });
        }
    }

    static async getTemplates(apiCaller, sessionID, queryParams) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/templates',
                method: 'GET',
                params: queryParams,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            // Convert the result to an array of Template instances
            return result.map(templateData => new Template(templateData));
        } catch (error) {
            console.error('Error fetching templates:', error);
            throw new Error(error.response?.data || { error: 'Failed to fetch templates' });
        }
    }

    static async getCollections(apiCaller, sessionID) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/collections',
                method: 'GET',
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result.collections;
        } catch (error) {
            console.error('Error fetching collections:', error);
            throw new Error(error.response?.data || { error: 'Failed to fetch collections' });
        }
    }
}

export default TemplatesService;
