import { useCallback } from 'react';
import LinksService from '../services/LinksService';
import APIContext from '../types/APIContext';

export const useLinkManagement = () => {
  
  const fetchLinks = useCallback(async (pRealmID, pRealmObjectID, ApiContext) => {
 
    const processLinks = async (links) => {
      return await Promise.all(links.map(async link => {
        const sourceObjectID = await LinksService.getRealmObjectID(link.PK_RealmID_RealmObjectID);
        const destinationObjectID = await LinksService.getRealmObjectID(link.SK_RealmID_RealmObjectID);
        return { SourceObjectID: sourceObjectID, DestinationObjectID: destinationObjectID };
      }));
    };
    

    try {
      const [incomingLinks, outgoingLinks] = await Promise.all([
        LinksService.getIncomingLinks(ApiContext.apiCaller, ApiContext.sessionID, { PK_RealmID_RealmObjectID: `${pRealmID}:${pRealmObjectID}` }),
        LinksService.getOutGoingLinks(ApiContext.apiCaller, ApiContext.sessionID, { PK_RealmID_RealmObjectID: `${pRealmID}:${pRealmObjectID}` }),
      ]);
      return { incomingLinks: await processLinks(incomingLinks.links), outgoingLinks: await processLinks(outgoingLinks.links) };
    } catch (error) {
      
      ApiContext.handleApiError(error, 'fetching links');
      return { incomingLinks: [], outgoingLinks: [] };
    }
  }, []);

  const createLink = useCallback(async (primaryObjectID, secondaryObjectID,ApiContext) => {
    try {
      const linkData = {
        PK_RealmID_RealmObjectID: `${ApiContext.realmID}:${primaryObjectID}`,
        SK_RealmID_RealmObjectID: `${ApiContext.realmID}:${secondaryObjectID}`,
      };
      await LinksService.createLink(ApiContext.apiCaller, ApiContext.sessionID, linkData);
    } catch (error) {
      ApiContext.handleApiError(error, 'creating link');
    }
  }, []);

  const deleteLink = useCallback(async (primaryObjectID, secondaryObjectID,ApiContext) => {
    try {
      const linkData = {
        PK_RealmID_RealmObjectID: `${ApiContext.realmID}:${primaryObjectID}`,
        SK_RealmID_RealmObjectID: `${ApiContext.realmID}:${secondaryObjectID}`,
      };
      await LinksService.deleteLink(ApiContext.apiCaller, ApiContext.sessionID, linkData);
    } catch (error) {
      ApiContext.handleApiError(error, 'deleting link');
    }
  }, []);
  
  return { fetchLinks, createLink, deleteLink };
};
