import React, { useEffect, useState } from 'react';
import { Row, Col, Drawer, Space, Grid } from 'antd';
import IconifiedTitle from './IconifiedTitle';
import UserStatusPanel from './UserStatusPanel';
import '../App.css';
import './css/SiteHeader.css';
import RealmInput from './RealmInput';
import UserStatusAvatar from './UserStatusAvatar';

const SiteHeader = () => {
  const [iconifiedTitleProps, setIconifiedTitleProps] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    setIconifiedTitleProps([
      { key: "title", value: `Campaign Fabricator` },
      { key: "iconFileIndex", value: 30 },
      { key: "iconIndex", value: 0 },
      { key: "iconDisplaySize", value: 65 },
      { key: "titleSize", value: "large" }
    ]);
  }, []);

  const props = iconifiedTitleProps
    ? iconifiedTitleProps.reduce((acc, curr) => {
        acc[curr.key] = curr.value;
        return acc;
      }, {})
    : {};

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Row className="site-header-row" align="middle" justify="space-between" style={{ display: 'flex', width: '100%' }}>
      {/* Left-aligned IconifiedTitle */}
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={14}
        xl={15}
        className="logo-col"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <IconifiedTitle {...props} />
      </Col>

      {/* Right-aligned RealmInput and UserStatusAvatar */}
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={10}
        xl={9}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
      >
        <Space size="large">
          {screens.lg && <RealmInput />}
          <UserStatusAvatar showDrawer={showDrawer} />
        </Space>
      </Col>

      {/* Drawer for User Status Panel */}
      <Drawer
        title="User Status"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
      >
        <UserStatusPanel drawerVisible={drawerVisible} />
      </Drawer>
    </Row>
  );
};

export default SiteHeader;
