import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ImageComponent = ({ blob, imageKey, onDelete }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setImageURL(url);

      // Cleanup function to revoke the object URL
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImageURL(null); // Clear the image URL if blob is null
    }
  }, [blob]);

  return (
    <div className='image-component' >
      {imageURL && (
        <>
          <img
            key={imageKey}
            src={imageURL}
            alt="blob"
            style={{
              objectFit: 'contain'  // Ensures the image scales while maintaining aspect ratio
            }}
            className='image-component'
          />
          {/* Delete Button */}
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            style={{ position: 'absolute', top: 10, right: 10 }}
            onClick={() => onDelete(imageKey)} // Call onDelete handler when clicked
          />
        </>
      )}
    </div>
  );
};

export default ImageComponent;
