export class CampaignObject {
    constructor(
      PK_CampaignID,   // Partition key for the campaign
      SK_RecordID,     // Sort key for the record
      GSI_PK_REALMID,  // Global Secondary Index Partition Key for Realm ID
      payload,         // The main content of the record (could be a JSON or string)
      type,            // Type of the record (e.g., metadata, content, etc.)
      created,         // Creation timestamp
      updated          // Last updated timestamp
    ) {
      this.PK_CampaignID = PK_CampaignID;
      this.SK_RecordID = SK_RecordID;
      this.GSI_PK_REALMID = GSI_PK_REALMID;
      this.payload = payload;
      this.type = type;
      this.created = created;
      this.updated = updated;
    }
  
    // Optional method to convert the record to a plain JSON object
    toJSON() {
      return {
        PK_CampaignID: this.PK_CampaignID,
        SK_RecordID: this.SK_RecordID,
        GSI_PK_REALMID: this.GSI_PK_REALMID,
        payload: this.payload,
        type: this.type,
        created: this.created,
        updated: this.updated,
      };
    }
  
    // Optional method to update certain fields of the record
    update(fields) {
      Object.keys(fields).forEach((key) => {
        if (this.hasOwnProperty(key)) {
          this[key] = fields[key];
        }
      });
      this.updated = new Date().toISOString(); // Automatically update the 'updated' timestamp
    }
  }

  export default CampaignObject;
  