import { useState, useCallback } from 'react';
import AuthorizationService from '../services/AuthorizationService';
import CampaignFabricatorAuthTokens from '../configuration/AuthorizationTokens'; // Import the token file

const useAuthorization = () => {
  const [authorizations, setAuthorizations] = useState([]);
  const [authProfile, setAuthProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const authorizationService = new AuthorizationService();

  // Access all available tokens from CampaignFabricatorAuthTokens
  const availableTokens = CampaignFabricatorAuthTokens.allTokens;

  // Function to check authorization for a token
  const checkAuthorization = useCallback(async (sessionID, token, profile) => {
    try {
      setLoading(true);
      setError(null);
      const response = await authorizationService.checkAuthorization(sessionID, token, profile);
      if (response.check === 'not-authorized') {  // Check if the token is not authorized 
        return false;
      }
      return true;
    } catch (err) {
      setError(err);
      console.error("Error checking authorization: ", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  // Function to get authorizations for multiple tokens
  const getAuthorizations = useCallback(async (sessionID, profile) => {
    try {
      setLoading(true);
      setError(null);
      const response = await authorizationService.getAuthorizations(sessionID, profile, availableTokens);
      setAuthorizations(response);
    } catch (err) {
      setError(err);
      console.error("Error getting authorizations: ", err);
    } finally {
      setLoading(false);
    }
  }, [availableTokens]);

  // Function to authorize a token
  const authorize = useCallback(async (sessionID, token, profile) => {
    try {
      setLoading(true);
      setError(null);
      const result = await authorizationService.authorize(sessionID, token, profile);
      setAuthProfile(result);
      return result;
    } catch (err) {
      setError(err);
      console.error("Error authorizing token: ", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  // Function to deauthorize a token
  const deauthorize = useCallback(async (sessionID, token, profile) => {
    try {
      setLoading(true);
      setError(null);
      const response = await authorizationService.deauthorize(sessionID, token, profile);
      return response;
    } catch (err) {
      setError(err);
      console.error("Error deauthorizing token: ", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  // Function to get an authorization profile for a list of tokens
  const getAuthProfile = useCallback(async (sessionID, tokenArray) => {
    try {
      setLoading(true);
      setError(null);
      const profile = await authorizationService.getAuthProfile(sessionID, tokenArray);
      setAuthProfile(profile);
      return profile;
    } catch (err) {
      setError(err);
      console.error("Error getting auth profile: ", err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    authorizations,
    authProfile,
    availableTokens,
    loading,
    error,
    checkAuthorization,
    getAuthorizations,
    authorize,
    deauthorize,
    getAuthProfile,
  };
};

export default useAuthorization;
