import React, { useState } from 'react';
import { InputNumber, Switch, Typography } from 'antd';  // Assuming you're using Ant Design
import '../css/antSwitch.css';  // Import your custom CSS

const ToggleOneToZero = ({ initialValue = 0, onToggleChange, labelOn = "On", labelOff = "Off" }) => {

  const { Text } = Typography;
  const [value, setValue] = useState(initialValue);

  // Function to handle toggle switch changes
  const handleToggleChange = (checked) => {
    const newValue = checked ? 1 : 0;  // Enforce the value to be 0 or 1
    setValue(newValue);
    if (onToggleChange) {
      onToggleChange(newValue);  // Trigger callback if provided
    }
  };

  return (
    <div>
        <div style={{ marginBottom: '0.5em' }}>
        {/* The toggle switch control */}
        <Text>{value}</Text>
        <Switch
          checked={value === 1}  // Checks if the value is 1 (on)
          onChange={handleToggleChange}  // Toggles between 0 and 1
          checkedChildren={labelOn}  // Customizable label for the "on" state
          unCheckedChildren={labelOff}  // Customizable label for the "off" state
        />
      </div>
    </div>
  );
};

export default ToggleOneToZero;
