import { useCallback } from 'react';
import RealmObject from '../models/RealmObject';

export const useRealmObjectCrud = (apiCaller, sessionID, username, realmID) => {
    
  const saveRealmObject = useCallback(async (realmObject) => {
    try {
      const response = await apiCaller.callApi({
        userID: username,
        service: '', 
        method: 'POST',
        authorization: sessionID,
        params: { RealmID: realmID, RealmObjectID: realmObject.RealmObjectID },
        body: realmObject,
      });

      return response;
    } catch (error) {
      console.error("Error saving RealmObject:", error);
      return null;
    }
  }, [apiCaller, username, sessionID, realmID]);

  const createRealmObjectFromTemplate = useCallback(async (template) => {
    const newRealmObject = new RealmObject(template, realmID);
    try {
      const response = await apiCaller.callApi({
        userID: username,
        service: '', // Replace with your endpoint for creation
        method: 'POST',
        authorization: sessionID,
        params: { RealmID: realmID },
        body: newRealmObject,
      });

      return response;
    } catch (error) {
      console.error("Error creating RealmObject:", error);
      return null;
    }
  }, [apiCaller, username, sessionID, realmID]);

  const deleteRealmObject = useCallback(async (realmObjectID) => {
    try {
      await apiCaller.callApi({
        userID: username,
        service: '', 
        method: 'DELETE',
        authorization: sessionID,
        params: { RealmID: realmID, RealmObjectID: realmObjectID },
      });

    } catch (error) {
      console.error("Error deleting RealmObject:", error);
    }
  }, [apiCaller, username, sessionID, realmID]);

    const updateRealmObjectValue = (object, key, value) => {
        if (!object) throw new Error('Object is required');
    
        const updatedRealmObject = new RealmObject({ ...object });
        switch (key)
        {
            case 'name': 
            updatedRealmObject.setName(value);
            break;
            case 'parentID': 
            updatedRealmObject.setParentID(value);
            break;
            case 'publishedLevelACL':
            updatedRealmObject.setPublishedLevelACL(value);
            break;
            case 'referenceID':
            updatedRealmObject.setReferenceID(value)
            break;          
            case 'iconIndex':
              updatedRealmObject.setIconIndex(value);
              break;
            case 'images':
              updatedRealmObject.setImages(value);
              break;
            case 'iconFileIndex':
              updatedRealmObject.setIconFileIndex(value);
              break;
            default:
                updatedRealmObject.updatePayload({ [key]: value });
        }
        return updatedRealmObject;
    };
  

  return { saveRealmObject, createRealmObjectFromTemplate, deleteRealmObject,updateRealmObjectValue  };
};
