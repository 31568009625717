import { message } from 'antd';
import PortalConfiguration from "../configuration/config";

class LinksService {

    static async getOutGoingLinks(apiCaller, sessionID, queryParams) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/links/PK_RealmObject',
                method: 'GET',
                params: queryParams,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result; // Adjust based on your actual response structure
        } catch (error) {
            console.error(`Error fetching Outgoing links:`, error);
            throw new Error(error.response?.data?.error || `Failed to fetch Outgoing links`);
        }
    }

    static async getIncomingLinks(apiCaller, sessionID, queryParams) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/links/SK_RealmObject',
                method: 'GET',
                params: queryParams,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result; // Adjust based on your actual response structure
        } catch (error) {
            console.error(`Error fetching Incoming links:`, error);
            throw new Error(error.response?.data?.error || `Failed to fetch Incoming links`);
        }
    }

    static async createLink(apiCaller, sessionID, linkData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/links',
                method: 'PUT',
                body: linkData,
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error creating link:', error);
            throw new Error(error.response?.data?.error || 'Failed to create link');
        }
    }

    static async deleteLink(apiCaller, sessionID, linkData) {
        try {
            const result = await apiCaller.callApi({
                authorization: sessionID,
                service: '/links',
                method: 'DELETE',
                params: {
                    PK_RealmID_RealmObjectID: linkData.PK_RealmID_RealmObjectID,
                    SK_RealmID_RealmObjectID: linkData.SK_RealmID_RealmObjectID,
                },
                headers: {
                    applicationID: PortalConfiguration.defaultApplicationID
                }
            });
            return result;
        } catch (error) {
            console.error('Error deleting link:', error);
            throw new Error(error.response?.data?.error || 'Failed to delete link');
        }
    }

    static async getRealmObjectID(linkAddress) {
        // Split the string by the colon character and return the second part
        const parts = linkAddress.split(':');
        const retValue = parts.length > 1 ? parts[1] : null; // Check if there is a second part
        return retValue;
    }
}

export default LinksService;
