import React from 'react';
import { Typography, Row, Col, Button, Space } from 'antd';
import EmptyControlContent from "./EmptyControlContent";
import LogoutButton from "./logoutButton";
import { useAuthContext } from '../contexts/AuthProvider'; // Ensure this path is correct
import CampaignFabricatorAuthTokens from '../configuration/AuthorizationTokens'; // Ensure this path is correct
import './css/NotAuthorized.css'; // Import CSS for styling

const { Title, Text } = Typography;

const NotAuthorized = () => {
  const { userPermissionLevel } = useAuthContext(); // Destructuring user context

  return (
    <Row justify="center" align="middle" className="not-authorized-row">
      <Col xs={22} sm={18} md={16} lg={12} xl={10}>
        <div className="frame">
          <Title level={2} className="custom-title">Campaign Fabricator - Realm Building</Title>
          <Text level={1} className="custom-title">
            You're currently subscribed as a{' '}
            {CampaignFabricatorAuthTokens.friendlyTokenStringFromValue(userPermissionLevel)}
          </Text>

          <EmptyControlContent emptyText="You aren't authorized to access the Realm Building Portal." />
          <Space direction='vertical' size='large'>
            {/* Head over to the Player Portal */}
            <Text className="text-secondary">
                Looking for the Player Portal?{' '}
                <a
                href="http://reader.campaignfabricator.com"
                className="text-orange"
                target="_blank"
                rel="noopener noreferrer"
                >
                Player Portal
                </a>
            </Text>

            {/* Get started with your own realm */}
            <Text className="text-primary large-size" style={{ marginTop: '16px' }}>
                Get started with your own realm by upgrading?{' '}
                <a
                href="http://campaignfabricator.com"
                className="text-orange large-size"
                target="_blank"
                rel="noopener noreferrer"
                >
                Upgrade Now
                </a>
            </Text>
          </Space>

          <div className="user-actions" style={{ marginTop: '24px' }}>
            <LogoutButton>Logout</LogoutButton>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NotAuthorized;
