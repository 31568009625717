import React, { useState } from 'react';
import { Input, Button, Row, Col, Typography } from 'antd';
import { UserAddOutlined, WarningOutlined } from '@ant-design/icons'; // Import the WarningOutlined icon
import useAccessControl from '../hooks/useAccessControl'; // Adjust the path to your hook
import AccessObject from '../models/accessControl'; // Adjust this import to the actual path
import { useRealmContext } from '../contexts/RealmProvider'; // Adjust the path to your context
import Namelist from './NameList'; // Adjust the path to your component
import { useAuthContext } from '../contexts/AuthProvider'; // Adjust the path to your context
import { useRealmObjectContext } from '../contexts/RealmObjectProvider'; // Adjust the path to your context

const UserAccessControl = ({ targetId, visibility }) => {
  const { Text } = Typography;
  const [username, setUsername] = useState(''); // State to track the input value
  const [errorMessage, setErrorMessage] = useState(''); // State to manage validation errors
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission loading
  const { realmID } = useRealmContext();
  const { allowedAccessors, realmObjectList } = useRealmObjectContext();
  const { checkForUserName } = useAuthContext(); // Use your custom hook for checking username
  const { createAccess, deleteAccess } = useAccessControl(); // Use your custom hook for creating access

  const validateUsername = async (name) => {
    try {
      return await checkForUserName(name);
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    }
  };

  // Handle the form submission
  const handleAddUser = async () => {
    setErrorMessage(''); // Clear any previous errors
    setIsSubmitting(true); // Show loading state
    try {
      const isValid = await validateUsername(username);
      if (!isValid) {
        setErrorMessage('Invalid username. Please enter a valid username.');
      } else {
        const name = realmObjectList.find(obj => obj.RealmObjectID === targetId).name;
        const newAccessData = new AccessObject({
          accessId: username,
          targetId: targetId,
          targetType: 'realmObject',
          payload: { realmID, name }
        });
        await createAccess(newAccessData); // Add access with username and targetId
        setErrorMessage(''); // Clear any errors on success
        setUsername(''); // Clear the input field after successful submission
      }
    } catch (error) {
      console.error('Error adding access:', error);
      setErrorMessage('Error adding user to access control list.');
    } finally {
      setIsSubmitting(false); // Stop loading state
    }
  };

  const handleDeleteAccess = async (accessControl) => {
    await deleteAccess(accessControl.accessId, accessControl.targetId);
    if (errorMessage !== '') {
      setErrorMessage(''); // Clear any errors on success
      setUsername(''); // Clear the input field after successful submission
    }
  };

  return (
    (visibility !== 'PRIVATE' || allowedAccessors.length > 0) ? (

      <Row gutter={[16, 16]}>
        {/* Input and Add Button */}
        <Col span={24}>
          <Row gutter={16} align="middle">
            <Col flex="auto">
              <Input
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onPressEnter={handleAddUser}
                className='large-size'
              />
            </Col>
            <Col>
              <Button
                icon={<UserAddOutlined />}
                onClick={handleAddUser}
                loading={isSubmitting}
                disabled={!username}
                className='secondary-btn large'
              />
            </Col>
          </Row>
        </Col>

        {/* Error Message */}
        {errorMessage && (
          <Col span={24}>
            <Text type="danger">
              <WarningOutlined className='element-margined-tiny' />
              {errorMessage}
            </Text>
          </Col>
        )}

        {/* Visibility-based Messages */}
        {visibility === 'PUBLIC' && (
          <Col span={24}>
            <Text className='text-secondary element-margined-small fab-fill-parent'>
              These folks will see the item listed in the Reader Portal.
            </Text>
          </Col>
        )}
        {visibility === 'LIMITED' && (
          <Col span={24}>
            <Text className='text-secondary element-margined-small fab-fill-parent'>
              These folks have access to the item from the Reader Portal.
            </Text>
          </Col>
        )}
        {visibility === 'PRIVATE' && allowedAccessors.length > 0 && (
          <Col span={24}>
            <Text className="text-danger element-margined-medium fab-fill-parent">
              <WarningOutlined className='element-margined-tiny' />
              Because you made this private: From the reader portal, this item will remain in the Authorized Users item list. However, these folks will get a "temporarily unavailable" notice if they access the item.
            </Text>
          </Col>
        )}

        {/* Namelist Component */}
        <Col span={24}>
          <Namelist key='RealmObjectAccess' publishedStatus={visibility} deleteHandler={handleDeleteAccess} allowedAccessors={allowedAccessors} />
        </Col>
      </Row>
    ) : (<Text className='text-secondary element-margined-small fab-fill-parent'>
      {'Change Published Level to share with your others!'}
    </Text>)
  );
};

export default UserAccessControl;
