// RealmLayout.js

import React, { useState } from 'react';
import { Layout, Button, Drawer, Typography, Grid, Splitter } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import TemplatePanel from './TemplatePanel';
import CenterPanel from './CenterPanel';
import EmptyControlContent from './EmptyControlContent'; // Import EmptyControlContent

const { Title } = Typography;
const { Content } = Layout;
const { useBreakpoint } = Grid;

const RealmLayout = ({ realmID }) => {
  const screens = useBreakpoint();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const openDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Layout className='main-background' style={{ minHeight: '100vh', width: '100%' }}>
      {screens.lg ? (
        // Render Splitter for larger screens
        <Splitter
          style={{
            height: '100%',
            width: '100%',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Splitter.Panel
            defaultSize={screens.xxl ? "15%" : "20%"}
            min="10%"
            max="50%"
            collapsible={{ start: true }}
            collapsedSize={0}
            resizable={false}
            style={{ overflow: 'auto' }} // Allow internal scrolling for overflow content
          >
            {/* Left Sider Panel with TemplatePanel */}
            <div className="left-panel" style={{ padding: '0 4px' }}>
              <TemplatePanel />
            </div>
          </Splitter.Panel>

          <Splitter.Panel style={{ overflow: 'auto' }}>
            {/* Main Content Panel */}
            <Content
              id='main-content'
              className='panel-background-medium panel-border-light'
              style={{
                padding: screens.xs ? '0 8px' : '0px 4px',
                minHeight: '400px',
              }}
            >
              {realmID ? (
                <CenterPanel id='center-panel' />
              ) : (
                // Render EmptyControlContent if realmID is null
                <EmptyControlContent />
              )}
            </Content>
          </Splitter.Panel>
        </Splitter>
      ) : (
        // Render Drawer for smaller screens
        <>
         
          <Drawer
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={3} className='custom-title center-element'>Templates</Title>
                <Button
                  icon={<CloseOutlined />}
                  onClick={closeDrawer}
                  style={{ border: 'none', boxShadow: 'none' }}
                />
              </div>
            }
            closable={false}
            placement="left"
            onClose={closeDrawer}
            open={drawerVisible}
            width="80%" // Customize as needed
            className='template-drawer'
          >
            <TemplatePanel />
          </Drawer>
          {/* Main Content Panel remains visible on smaller screens */}
          <Content
            id='main-content'
            className='panel-background-medium panel-border-light'
            style={{
              width: '100%',
              padding: screens.xs ? '0 8px' : '0px 4px',
              minHeight: '400px',
            }}
          > 
          <Button
            icon={<MenuOutlined />}
            onClick={openDrawer}
            className='expand-button'
          />
            {realmID ? (
              <CenterPanel id='center-panel' />
            ) : (
              // Render EmptyControlContent if realmID is null
              <EmptyControlContent />
            )}
          </Content>
        </>
      )}
    </Layout>
  );
};

export default RealmLayout;
