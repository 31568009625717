import React, { useState } from 'react';
import { List, Spin, Space, Typography, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import EmptyControlContent from './EmptyControlContent';
import QuestionCard from './QuestionCard';
import AddAttributeModal from './AddAttributeModal';
import AttributeCard from './AttributeCard';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import './css/attributeModal.css';
import './css/ContextTab.css';

const { Title } = Typography;

const ContextTab = ({ questions, attributes, loading, handleQuestionChange, focusedInput, setFocusedInput, handleAddAttribute, handleDeleteAttribute }) => {
  const {realmObject} = useRealmObjectContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Custom loading icon
  const antIcon = <LoadingOutlined style={{ fontSize: 10 }} spin />;

  const showModal = () => {
    console.log('realmobject',realmObject)
    setIsModalVisible(true);
  };

  const handleAttributeModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleAttributeModalAdd = (newValue) => {
    console.log('realmobject',realmObject)
    handleAddAttribute(newValue);
    setIsModalVisible(false);
  };

  const handleDelete = (name) => {
    handleDeleteAttribute(name);

    // Trigger re-render by setting an empty array when the last item is deleted
    if (attributes.length === 1) {
      setFocusedInput([]); // Clear the focused input state
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="attributes-header">
        <Title level={3} className="custom-title element-margined-small-left">Attributes</Title>
        {realmObject && ( 
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={showModal} 
          className="add-attribute-button"
          
        />
        )}
      </div>
      
      {attributes.length > 0 && (
        <List
          dataSource={attributes}
          className={`primary-text ${loading ? 'fade-out' : ''}`}  // Apply fade-out class when loading
          renderItem={item => (
            <AttributeCard
              attribute={item}
              handleDelete={handleDelete}
            />
          )}
        />
      )}
      
      <AddAttributeModal 
        isVisible={isModalVisible}
        newName={attributes.length + 1}
        onAdd={handleAttributeModalAdd}
        onCancel={handleAttributeModalCancel}
      />

      <Title level={3} className="custom-title element-margined-small-left">Contextual Information</Title>
      <List
        className={`custom-list ${loading ? 'fab-panel-loading fade-out' : ''} tab-container`}
        itemLayout="vertical"
        loading={{ spinning: loading, indicator: <Spin indicator={antIcon} /> }}
        dataSource={questions}
        locale={{ emptyText: <EmptyControlContent /> }}
        renderItem={item => (
          <QuestionCard
            key={item.Label}
            item={item}
            inputValue={item.CurrentValue || ''}
            handleChange={handleQuestionChange}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
          />
        )}
      />
    </Space>
  );
};

export default React.memo(ContextTab);
