import PortalConfiguration from '../configuration/config';
import ApiCaller from './ApiCaller';

export class AuthorizationService {
    constructor() {
        this.apiCaller = new ApiCaller(PortalConfiguration.authorizationURL);
    }

    async checkAuthorization( sessionID, token, profile) {
        const service = '/check';
        const method = 'GET';
        const headers = {
            'x-token': token,
            'x-profile': profile
        };
        return await this.apiCaller.callApi({ authorization: sessionID, service, method, headers });
    }

    async getAuthorizations(sessionID, profile,tokenArray) {
        if (tokenArray.length === 0) return [];
        let authorizations = [];
        for (const token of tokenArray) {
            const response = await this.checkAuthorization(sessionID, token.value, profile);
            if (response.check === 'authorized') authorizations.push(token.value);
        }
        return authorizations;
    }

    async authorize(sessionID, token, profile) {
        const service = '/authorize';
        const method = 'POST';
        const headers = {
            'x-token': token,
            'x-profile': profile
        };
        const result = await this.apiCaller.callApi({ authorization: sessionID, service, method, headers });
        return result.authorizationProfile;

    }

    async deauthorize(sessionID, token, profile) {
        const service = '/deauthorize';
        const method = 'POST';
        const headers = {
            'x-token': token,
            'x-profile': profile
        };
        return await this.apiCaller.callApi({ authorization: sessionID, service, method, headers });
    }

    async getAuthProfile(sessionID, tokenArray) {
        let authorizationProfile = null;
        for (const token of tokenArray) {
            authorizationProfile = await this.authorize(sessionID, token, authorizationProfile);
        }
        console.log("authorizationProfile: ", authorizationProfile);
        return authorizationProfile;
    }
    
}

export default AuthorizationService;