import React, { useState } from 'react';
import { List, Button, Form, Input, Row, Col, Typography, Modal } from 'antd';
import { useCampaignContext } from '../contexts/CampaignProvider'; // Import the Campaign context
import { useRealmContext } from '../contexts/RealmProvider'; // Import the Realm context
import CampaignRecordService from '../services/CampaignRecordService'; // Import the Campaign service
import { DeleteOutlined, ExpandOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import EmptyControlContent from './EmptyControlContent';
import { useAuthContext } from '../contexts/AuthProvider';
import { useAppContext } from '../contexts/AppProvider'; // Import the App context
import useAccessControl from '../hooks/useAccessControl'; // Import the useAccessControl hook
import './css/CampaignManager.css';

const { Text } = Typography;

const CampaignManager = () => {
  const { campaignList = [], selectCampaign, campaign, updateCampaignList } = useCampaignContext(); // Ensure campaignList is an array by default
  const { realmID } = useRealmContext();
  const campaignService = CampaignRecordService();
  const { featureFlags } = useAppContext();
  const { userPermissionLevel } = useAuthContext();
  const { deleteAccess } = useAccessControl(); // Import the deleteAccess function

  // State for adding new campaigns
  const [newCampaignName, setNewCampaignName] = useState('');
  const [form] = Form.useForm();

  const allowMultipleCampaigns = () => {
    const allow =
      featureFlags.getUserFlag(userPermissionLevel, 'CREATE_MULTIPLE_CAMPAIGNS') > 0 || campaignList.length < 1;
    return allow;
  };

  // Function to add a new campaign
  const handleAddCampaign = async () => {
    if (newCampaignName.trim() && realmID) {
      console.log(`Adding new campaign: ${newCampaignName}`);
      await campaignService.createCampaign(newCampaignName, realmID, { name: newCampaignName });
      updateCampaignList();
      setNewCampaignName('');
      form.resetFields();
    }
  };

  // Function to show the confirmation modal
  const showDeleteConfirm = (item) => {
    Modal.confirm({
      title: 'Are you sure you want to delete "' + item.payload.name + '?"',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'info',
      cancelText: 'Cancel',
      onOk() {
        handleDeleteCampaign(realmID, item); // Call the delete handler when the user confirms
      },
    });
  };

  const handleDeleteCampaign = async (realmID, item) => {
    const campaignUsers = await campaignService.getCampaignUsers(item.PK_CampaignID);
    console.log('Deleting campaign:', item, campaignUsers);
    // Delete all users from the campaign concurrently
    await Promise.all(campaignUsers.map(user => deleteAccess(user.SK_RecordID, user.PK_CampaignID, true)));
  
    await campaignService.deleteCampaign(realmID, item);
  
    if (campaign && item.PK_CampaignID === campaign.PK_CampaignID) {
      selectCampaign(null);
    }
  
    updateCampaignList();
  };

 const sortedList = (cList) => {
    const retValue = cList.sort((a, b) => {
      if (a.payload.name < b.payload.name) {
        return -1;
      }
      if (a.payload.name > b.payload.name) {
        return 1;
      }
      return 0;
    });
    return retValue;
 }

  // Function to handle campaign selection
  const handleSelectCampaign = (campaignId) => {
    selectCampaign(campaignId);
  };

  return (
    <div className="campaign-panel-main">
      <Row className="fill-parent element-margined-medium">
        <Col span={24}>
          <List
            locale={{ emptyText: <EmptyControlContent emptyText="Start your first campaign" /> }}
            dataSource={Array.isArray(campaignList) ? sortedList(campaignList) : []} // Ensure dataSource is an array
            renderItem={(item) => (
              <List.Item
                key={item.PK_CampaignID}
                className={campaign?.PK_CampaignID === item.PK_CampaignID ? 'selected-campaign' : 'not-selected-campaign'} // Add conditional class
                onClick={() => handleSelectCampaign(item.PK_CampaignID)}
                actions={[
                  <Button icon={<DeleteOutlined />} type="link" onClick={() => showDeleteConfirm(item)} />,
                ]}
              >
                <Text className="text-hightlight center-title text-large">
                  {item.payload?.name ?? 'Unnamed Campaign'} {/* Safely access payload.name */}
                </Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Row className='element-margined-small-all
      '>
        {allowMultipleCampaigns() ? (
          <Col span={24}>
            <Form form={form} layout="inline" onFinish={handleAddCampaign}>
              <Form.Item name="newCampaign" rules={[{ required: true, message: 'Enter a campaign name' }]}>
                <Input
                  placeholder="New Campaign"
                  value={newCampaignName}
                  onChange={(e) => setNewCampaignName(e.target.value)}
                  className='campaign-add-input'
                />
              </Form.Item>
              <Form.Item>
                <Button icon={<AppstoreAddOutlined />} type="primary" htmlType="submit">
                  Campaign
                </Button>
              </Form.Item>
            </Form>
          </Col>
        ) : (
          <Text className="text-secondary element-margined-tiny fill-width center-element">
            Need more campaigns? Upgrade to Game Master
          </Text>
        )}
      </Row>
    </div>
  );
};

export default CampaignManager;
