import { useRealmObjectContext } from '../contexts/RealmObjectProvider';

function useRealmObjectImages() {
    const { images, setImages } = useRealmObjectContext();
    const addImage = (imageItem, imageBlob) => {
        setImages(prevImages => [...prevImages, { imageItem, imageBlob }]);
    };
    const removeImage = (imageItemToRemove) => {
        setImages(prevImages => prevImages.filter(({ imageItem }) => imageItem !== imageItemToRemove));
    }
    const clearImages = () => {
        setImages([]);
    }
    const loadImages = (imageArray) => {
        setImages(imageArray);
    }
    const getImagebyFilename = (filename) => {
        return images.find(image => image.imageItem.filename === filename);
    }

    const getImagebyLocation = (location) => {
        return images.find(image => image.imageItem.payload.location === location);
    }
    
    return { addImage, removeImage, clearImages, loadImages,getImagebyFilename, getImagebyLocation };
    }

export default useRealmObjectImages;