export class TemplateManager {
    static templates = [];

    static getTemplates() {
        return this.templates;
    }

    static addTemplate(template) {
        if (!(template instanceof Template)) {
            throw new Error("Invalid template object");
        }
        this.templates.push(template);
    }

    static setTemplate(templateID, updatedTemplate) {
        if (!(updatedTemplate instanceof Template)) {
            throw new Error("Invalid template object");
        }

        const index = this.templates.findIndex(t => t.templateID === templateID);
        if (index !== -1) {
            this.templates[index] = updatedTemplate;
        } else {
            throw new Error("Template with the given templateID not found");
        }
    }

    static subtractTemplate(templateID) {
        const index = this.templates.findIndex(t => t.templateID === templateID);
        if (index !== -1) {
            this.templates.splice(index, 1);
        } else {
            throw new Error("Template with the given templateID not found");
        }
    }

    static filterByCollectionID(collectionID) {
        return this.templates.filter(t => t.collectionID === collectionID);
    }

    // Method to convert templates into Ant Tree compatible data
    static getTreeData() {
        const collectionMap = {};

        // Group templates by collectionID
        this.templates.forEach(template => {
            if (!collectionMap[template.collectionID]) {
                collectionMap[template.collectionID] = [];
            }
            collectionMap[template.collectionID].push(template);
        });

        // Convert grouped templates into tree data
        const treeData = Object.keys(collectionMap).map(collectionID => ({
            title: `Collection: ${collectionID}`,
            key: collectionID,
            children: collectionMap[collectionID].map(template => ({
                title: `Template: ${template.templateID}`,
                key: template.templateID,
                isLeaf: true, // Mark as leaf node
                payload: template.payload, // Additional data can be passed here
            })),
        }));

        return treeData;
    }
}

export class Template {
    constructor(data) {
        this.payload = new Payload(data.payload);
        this.created = data.created;
        this.name = data.name;
        this.templateID = data.templateID;
        this.updated = data.updated;
        this.compoundID = data.compoundID;
        this.collectionID = data.collectionID;
    }
}

class Payload {
    constructor(payload) {
        this.iconFileIndex = payload.iconFileIndex;
        this.templateDescription = payload.templateDescription;
        this.AIMODE = payload.AIMODE;
        this.Questions = payload.Questions.map(question => new Question(question));
        this.MaxGAILongStoryLength = payload.MaxGAILongStoryLength;
        this.iconIndex = payload.iconIndex;
        this.category = payload.category; // Added new field
    }
}

class Question {
    constructor(question) {
        this.ResponseFormat = question.ResponseFormat;
        this.Description = question.Description;
        this.Label = question.Label;
        this.PromptNotes = question.PromptNotes;
        this.ContextNotes = question.ContextNotes;
        this.strategy = question.strategy; // Added new field
    }
}

export { Payload, Question };

export default TemplateManager;
