import { useCallback, useMemo } from 'react';
import ApiCaller from '../services/ApiCaller';
import RealmObject from '../models/RealmObject';
import PortalConfiguration from '../configuration/config';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import useRealmContext from '../contexts/RealmProvider';
import { useLinkManagement } from './useLinkManagement';
import APIContext from '../types/APIContext';

export const useRealmObjectFetching = (apiCaller, sessionID, username, handleApiError) => {
  const {
    incomingRealmObjectLinks, 
    setIncomingRealmObjectLinks,
    outgoingRealmObjectLinks, 
    setOutgoingRealmObjectLinks,
    realmObjectList, realmObject,
    realmObjectID, setRealmObject, setRealmObjectList,
    setLoading
  } = useRealmObjectContext();
  
  const linkAPIContext = useMemo(() => new APIContext(apiCaller, sessionID, '', handleApiError), [apiCaller, sessionID, handleApiError]);
  
  const { fetchLinks } = useLinkManagement();
  
  const fetchRealmObjectList = useCallback(async (realmId, quietly = false) => {
    const apiCaller = new ApiCaller(PortalConfiguration.realmobjectsURL);
    setLoading('isLoadinglist', !quietly);
    try {
      const response = await apiCaller.callApi({
        userID: username,
        service: '', // Replace with your endpoint
        method: 'GET',
        authorization: sessionID,
        params: { RealmID: realmId },
      });

      if (Array.isArray(response)) {
        setRealmObjectList(response); // Set the realm object list
      } else {
        console.error('Expected array but got:', response);
        setRealmObjectList([]);
      }
      
    } catch (error) {
      handleApiError(error, 'fetching list');
      setRealmObjectList([]);
    } finally {
      setLoading('isLoadinglist', false);
    }
  }, [setLoading, username, sessionID, setRealmObjectList, handleApiError]);

  const fetchRealmObject = useCallback(async (realmID, realmObjectId = realmObjectID) => {
    setLoading('isLoadingObject', true);
    try {
      const response = await apiCaller.callApi({
        userID: username,
        service: '', // Replace with your endpoint
        method: 'GET',
        authorization: sessionID,
        params: { RealmID: realmID, RealmObjectID: realmObjectId },
      });

      if (response && response.name) {
       const responseRealmObject = new RealmObject(response);
       setRealmObject(responseRealmObject); // Set the realmObject state here
       setRealmObjectList(
        realmObjectList.map(item => 
          item.RealmObjectID === responseRealmObject.RealmObjectID ? responseRealmObject : item
        )
      );    
      } else {
        console.error('Expected object but got:', response);
        setRealmObject(null);
      }
    } catch (error) {
      handleApiError(error, 'fetching');
      setRealmObject(null);
    } finally {
      setLoading('isLoadingObject', false);
    }
  }, [realmObjectID, setLoading, apiCaller, username, sessionID, setRealmObject, setRealmObjectList, realmObjectList, handleApiError]);
  
  const getReferenceID = useCallback(async (realmID, realmObjectID) =>{
        try {
            const response = await apiCaller.callApi({
                userID: username,
                service: '/reference', // Replace with your endpoint
                method: 'GET',
                authorization: sessionID,
                params: { RealmID: realmID, RealmObjectID: realmObjectID },
            });

            return response || null;
        } catch (error) {
            console.error('Error fetching reference ID:', error);
            return null;
        }
    }, []);
  
  const getPublicLink = useCallback(async (realmID, realmObjectID) =>{
        const referenceID = await getReferenceID(realmID, realmObjectID);
        if (!referenceID) {
            console.error('Failed to get reference ID');
            return null;
        }
        return `${PortalConfiguration.readerURL}?referencedID=${realmObject.referenceID}`;
    }, []);

    
  const getIncomingRealmObjectLinks = useCallback(async (pRealmID,pRrealmObjectID) => {
    
     {
      setLoading('isFetchingLinks', true);
      const rlinkApiContext = {...linkAPIContext, realmID: pRealmID, apiCaller: new ApiCaller(PortalConfiguration.linkURL)}
      const { incomingLinks, outgoingLinks } = await fetchLinks(pRealmID,pRrealmObjectID,rlinkApiContext);
      await setIncomingRealmObjectLinks(incomingLinks);
      await setOutgoingRealmObjectLinks(outgoingLinks);
      setLoading('isFetchingLinks', false);
    }
    return incomingRealmObjectLinks;
  }, [incomingRealmObjectLinks, setLoading, fetchLinks, linkAPIContext, setIncomingRealmObjectLinks, setOutgoingRealmObjectLinks]);
  
  //outGoingRealmObjectLinks
  const getOutgoingRealmObjectLinks = useCallback(async (pRealmID,pRrealmObjectID) => {
    {
      setLoading('isFetchingLinks', true);
      const rlinkApiContext = {...linkAPIContext, realmID: pRealmID, apiCaller: new ApiCaller(PortalConfiguration.linkURL)}
      const { incomingLinks, outgoingLinks } = await fetchLinks(pRealmID,pRrealmObjectID,rlinkApiContext);
      setIncomingRealmObjectLinks(incomingLinks);
      setOutgoingRealmObjectLinks(outgoingLinks);
      setLoading('isFetchingLinks', false);
    }
    return outgoingRealmObjectLinks;
  }, [outgoingRealmObjectLinks, setLoading, fetchLinks, linkAPIContext, setIncomingRealmObjectLinks, setOutgoingRealmObjectLinks]);



  return { getIncomingRealmObjectLinks, getOutgoingRealmObjectLinks, getPublicLink, fetchRealmObjectList, fetchRealmObject, getReferenceID};
};



