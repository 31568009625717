import React, { useState } from 'react';
import { Input, Button, Row, Col, Typography, Select, Space } from 'antd';
import { GroupOutlined, WarningOutlined } from '@ant-design/icons'; // Import the WarningOutlined icon
import useAccessControl from '../hooks/useAccessControl'; // Adjust the path to your hook
import AccessObject from '../models/accessControl'; // Adjust this import to the actual path
import { useRealmContext } from '../contexts/RealmProvider'; // Adjust the path to your context
import Namelist from './NameList'; // Adjust the path to your component
import { useAuthContext } from '../contexts/AuthProvider'; // Adjust the path to your context
import { useRealmObjectContext } from '../contexts/RealmObjectProvider'; // Adjust the path to your context
import { useCampaignContext } from '../contexts/CampaignProvider'; // Adjust the path to your context
import './css/CampaignAccessControl.css'

const CampaignAccessControl = ({ targetId, visibility }) => {
  const { Text } = Typography;
  const { Option } = Select;
  const [username, setUsername] = useState(''); // State to track the input value
  const [errorMessage, setErrorMessage] = useState(''); // State to manage validation errors
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission loading
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const { realmID } = useRealmContext();
  const { allowedAccessors, allowedCampaigns, realmObjectList, realmObjectID } = useRealmObjectContext();
  const { checkForUserName } = useAuthContext(); // Use your custom hook for checking username
  const { createAccess, deleteAccess } = useAccessControl(); // Use your custom hook for creating access
  const {
    campaign,
    campaignList
  } = useCampaignContext();

  
  const handleAddCampaign = async () => {
    setErrorMessage(''); // Clear any previous errors
    setIsSubmitting(true); // Show loading state
    try {
        const name = realmObjectList.find(obj => obj.RealmObjectID === targetId).name;
        //if selectedCampaign is in the allowedCampaigns, then don't add it again
        if (!allowedCampaigns.some(campaign => campaign.accessId === selectedCampaign)) {
        const newAccessData = new AccessObject({
          accessId: selectedCampaign,
          targetId: targetId,
          targetType: 'realmObject',
          payload: { realmID, name, campaign: selectedCampaign }
        });
        await createAccess(newAccessData); // Add access with campaignid and targetId
        setErrorMessage(''); // Clear any errors on success
        setUsername(''); // Clear the input field after successful submission
        }
        else {
            setErrorMessage('Campaign already added to the list');
        }
      }
    catch (error) {
      console.error('Error adding access:', error);
      setErrorMessage('Error adding user to access control list.');
    } finally {
      setIsSubmitting(false); // Stop loading state
    }
  };
        

  const handleSelectChange = (value) => {
    setSelectedCampaign(value);
    };

  const handleDeleteCampaign = async (accessControl) => {
    await deleteAccess(accessControl.accessId, accessControl.targetId, true);
    if (errorMessage !== '') {
      setErrorMessage(''); // Clear any errors on success
      setUsername(''); // Clear the input field after successful submission
    }
  };

  return (
    (visibility !== 'PRIVATE' || allowedCampaigns.length > 0) ? (
    <>
        {(campaignList && campaignList.length > 0) ? (
            <Row gutter={[16, 16]}>
            {/* Input and Add Button */}
            <Col span={24}>
                <Row gutter={16} align="middle">
                <Col flex="auto">
                    <Select
                        className="campaign-select"
                        popupClassName="small-select"
                        value={selectedCampaign}
                        onChange={handleSelectChange}
                        style={{ width: 200 }}
                        placeholder={<Text className='custom-title'>Pick a Campaign</Text>} 
                        > {campaignList.map((item) => (
                            <Option key={item.PK_CampaignID} value={item.PK_CampaignID}>
                            {item.payload.name}
                            </Option>
                        ))}
                        </Select>
                </Col>
                <Col>
                    <Button
                    icon={<GroupOutlined />}
                    onClick={handleAddCampaign}
                    loading={isSubmitting}
                    disabled={!selectedCampaign}
                    className='secondary-btn large'
                    />
                </Col>
                </Row>
            </Col>

            {/* Error Message */}
            {errorMessage && (
                <Col span={24}>
                <Text type="danger">
                    <WarningOutlined className='element-margined-tiny' />
                    {errorMessage}
                </Text>
                </Col>
            )}

            {/* Visibility-based Messages */}
            {visibility === 'PUBLIC' && (
                <Col span={24}>
                <Text className='text-secondary element-margined-small fab-fill-parent'>
                    The items shared with the campaign will appear in the Reader Portal, under the Campaign information.
                </Text>
                </Col>
            )}
            {visibility === 'LIMITED' && (
                <Col span={24}>
                <Text className='text-secondary element-margined-small fab-fill-parent'>
                The items shared with the campaign will appear in the Reader Portal, under the Campaign information.
                </Text>
                </Col>
            )}
            {visibility === 'PRIVATE' && allowedCampaigns.length > 0 && (
                <Col span={24} >
                  <Space direction='horiztonal'>
                    <Text className="text-danger element-margined-medium fab-fill-parent">
                      <WarningOutlined className='element-margined-tiny' />
                      Because you made this private: From the reader portal, this item will remain in the Campaign item list. However, these folks will get a "temporarily unavailable" notice if they access the item.
                    </Text>
                  </Space>
                </Col>
            )}

            {/* Namelist Component */}
            <Col span={24}>
                <Namelist key='CampaignAccess' publishedStatus={visibility} deleteHandler={handleDeleteCampaign} allowedAccessors={allowedCampaigns} />
            </Col>
            </Row>
            ) : (
              <Text className='text-secondary element-margined-small fab-fill-parent'>
                {(campaignList && campaignList.length > 0) ? 'Change Published Level to share with your campaigns!' : 'Create a campaign to share with!'}
              </Text>)}
          </>
      ) : (<Text className='text-secondary element-margined-small fab-fill-parent'>
        {(campaignList && campaignList.length > 0) ? 'Change Published Level to share with your campaigns!' : 'Create a campaign to share with!'}
      </Text>)
    );
};

export default CampaignAccessControl;
